.card {
  flex: 1 1 calc(50% - 1.5vw);
  display: flex;
  flex-direction: column;
  padding: 1.5vw 2vw;
  border-radius: 15px;
  border: 1px solid #ccc;
  background: #fff;
  transition: border 0.4s ease-in-out, transform 0.4s ease-in-out;
  overflow: hidden;
  align-items: flex-start;
  justify-content: space-between;
  height: 16vw;
  position: relative;
}

.chooseUsContainer {
  display: flex;
  flex-direction: column;
  gap: 5vw;
}

@media (min-width: 768px) {
  .chooseUsContainer {
    flex-direction: row;
    margin: 5vw 6vw 5vw 5vw;
  }
}

.leftCol {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2vw;
}

.heading {
  color: #0e181e;
  font-family: "DM Sans", sans-serif;
  font-size: 3vw;
  font-weight: 700;
  line-height: normal;
  text-align: left;
  margin: 0;
}

.description {
  color: #5d6266;
  font-family: "DM Sans", sans-serif;
  font-size: 1.3vw;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  margin: 0;
  width: 44vw;
}

.btnDiv {
  display: flex;
  justify-content: flex-start; /* Adjust alignment of button container */
}

.ctaButton {
  color: #333f92;
  border: 1px solid #333f92;
  border-radius: 7.6vw;
  padding: 1vw 2.4vw;
  font-family: "DM Sans", sans-serif;
  font-size: 1.2vw;
  font-weight: 500;
  line-height: 1.8vw;
  background-color: transparent;
  text-decoration: none; /* Remove underline from Link */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.ctaButton:hover {
  background-color: #333f92;
  color: #fff;
}

.image {
  width: 40vw;
  height: 18vw;
  object-fit: cover;
  border-radius: 1.2vw;
}

.rightCol {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 1.5vw;
}

.card {
  flex: 1 1 calc(50% - 1.5vw);
  display: flex;
  flex-direction: column;
  padding: 1.5vw 2vw;
  border-radius: 15px;
  border: 1px solid #ccc;
  background: #fff;
  transition: border 0.4s ease-in-out, transform 0.4s ease-in-out;
  overflow: hidden;
  align-items: flex-start;
  justify-content: space-between;
  height: 16vw;
  position: relative;
}

.icon {
  font-size: 2vw;
  margin-bottom: 0.5vw;
}

.cardTitle {
  color: #0e181e;
  font-family: "Inter", sans-serif;
  font-size: 1.3vw;
  font-weight: 600;
  line-height: 2.52vw;
  text-align: left;
  margin: 0;
}

.cardDescription {
  color: #6f7c8e;
  font-family: "Inter", sans-serif;
  font-size: 1.1vw;
  font-weight: 400;
  line-height: 1.5vw;
  text-align: left;
  margin: 0;
  margin-bottom: 0.5vw;
}

/* Media queries for small devices */
@media (max-width: 768px) {
  .content {
    margin-top: 3rem;
  }

  .chooseUsContainer {
    display: flex;
    flex-direction: column;
    margin-top: 3%;
    /* padding: 2vw 5vw; */
    gap: 5vw;
  }

  .btnDiv {
    justify-content: center;
  }

  .heading {
    font-size: 6vw;
    text-align: center;
    margin: 5% 0;
  }

  .description {
    font-size: 3vw;
    width: 100%;
    text-align: center;
    margin-bottom: 4%;
  }

  .ctaButton {
    padding: 3%;
    width: 36%;
    font-size: 3.5vw;
    line-height: 2.5vw;
    border-radius: 8vw;
  }

  .image {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin: 3% auto;
  }

  .rightCol {
    display: grid;
    gap: 3vw;
    flex-direction: column;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
  }

  .card {
    padding: 3vw 4vw;
    max-width: 100%;
    height: auto;
    min-height: 31vw; /* Set a minimum height to ensure consistency */
    flex: 1 1 100%;
    align-items: center;
    justify-content: start;
  }

  .icon {
    font-size: 3vw;
    text-align: center;
    margin: 2vw 0;
  }

  .cardTitle {
    font-size: 4vw;
    line-height: 6vw;
    text-align: center;
    margin: 2vw 0;
  }

  .cardDescription {
    font-size: 4.5vw;
    line-height: 6vw;
    text-align: center;
    margin: 2vw 0;
    display: none;
  }
}

@media (max-width: 480px) {
  .chooseUsContainer {
    padding: 2vw 5vw;
  }
  .content {
    margin-top: 3em; /* Adjust margin for very small devices like phones */
  }

  .rightCol {
    display: grid;
    gap: 3vw;
    flex-direction: column;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
  }

  .card {
    min-height: 35vw;
  }

  .cardDescription {
    font-size: 4.5vw;
    line-height: 6vw;
    text-align: center;
    margin: 2vw 0;
    display: none;
  }
  .heading {
    font-size: 6vw;
    text-align: center;
    margin: 5% 0;
  }
  .ctaButton {
    padding: 3% 4%;
    width: 36%;
    font-size: 3.5vw;
    line-height: 18px;
    border-radius: 8vw;
  }
}
