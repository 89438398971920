.banner {
  background-color: #090f35;
  padding: 40px 20px;
  color: white;
  text-align: left;
  position: relative;
  overflow: hidden; /* Ensure no overflow of content */
}

.imgoneDiv {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1; /* Set lower z-index to be below the button */
}

.imgtwoDiv {
  position: absolute;
  bottom: -23px;
  left: -10px;
  z-index: 1; /* Set lower z-index to be below the button */
}

.bannerDiv {
  padding: 0 5%;
  width: 879px;
  position: relative;
  z-index: 2; /* Ensure banner content is above images */
}

.heading {
  color: #fff;
  font-family: "DM Sans";
  font-size: 4vw;
  font-style: normal;
  font-weight: 700;
  line-height: 58px;
  margin-bottom: 20px;
}

.description {
  color: #fff;
  font-family: "DM Sans";
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 30px;
}

.btnDiv {
  position: relative;
  z-index: 3; /* Ensure button div is on top of everything else */
}

.quoteButton {
  display: flex;
  padding: 18px 24px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 76px;
  border: 1px solid #fff;
  color: #fff;
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  background: transparent;
  width: 25%;
  position: relative;
  z-index: 4; /* Ensure button is on top of everything else */
}

/* Responsive Design */

/* Large Desktop */
@media (min-width: 1200px) {
  .banner {
    padding: 5% 2.5%;
  }

  .bannerDiv {
    padding: 0 4%;
    width: 60%;
  }

  .heading {
    font-size: 4vw;
    line-height: normal;
  }

  .description {
    font-size: 1.5vw;
    line-height: normal;
  }

  .quoteButton {
    font-size: 16px;
    padding: 2% 3%;
  }
}

/* Medium Desktop */
@media (min-width: 992px) and (max-width: 1199px) {
  .banner {
    padding: 6% 3%;
  }

  .bannerDiv {
    padding: 0 4%;
    width: 70%;
  }

  .heading {
    font-size: 48px;
  }

  .description {
    font-size: 18px;
  }

  .quoteButton {
    font-size: 16px;
    padding: 2% 3%;
  }
}

/* Tablet */
@media (min-width: 768px) and (max-width: 991px) {
  .banner {
    padding: 8% 4%;
  }

  .bannerDiv {
    padding: 0 5%;
    width: 100%;
    text-align: center;
  }

  .heading {
    font-size: 48px;
  }

  .description {
    font-size: 18px;
  }

  .quoteButton {
    font-size: 16px;
    padding: 2% 3%;
  }
}

/* Mobile */
@media (max-width: 767px) {
  .imgoneDiv,
  .imgtwoDiv {
    display: none;
  }
  .banner {
    margin-top: 10%;
    padding: 8% 5%;
  }
  .btnDiv {
    position: relative;
    z-index: 3;
    display: flex;
    justify-content: center;
  }

  .quoteButton {
    font-size: 3vw;
    padding: 3% 3%;
    width: 36%;
  }
  .bannerDiv {
    padding: 0;
    width: 100%;
  }

  .heading {
    text-align: center;
    font-size: 6vw;
    line-height: 7vw;
  }

  .description {
    font-size: 3vw;
    text-align: center;
  }

  .quoteButton {
    font-size: 3vw;
    padding: 3% 4%;
  }
}

/* Small Mobile */
@media (max-width: 480px) {
  .banner {
    margin-top: 10%;
    padding: 10% 0%;
  }

  .bannerDiv {
    padding: 0 5%;
    width: 100%;
  }

  .btnDiv {
    display: flex;
    justify-content: center;
  }

  .heading {
    text-align: center;
    font-size: 7vw;
    line-height: 9vw;
  }

  .description {
    font-size: 3.2vw;
    color: #fff;
    text-align: justify;
    font-family: "DM Sans";
    /* font-size: 12px; */
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .quoteButton {
    font-size: 3.5vw;
    padding: 3% 4%;
  }
}
