@import url("https://fonts.googleapis.com/css2?family=Bevan:ital@0;1&family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Epilogue:ital,wght@0,100..900;1,100..900&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Manrope:wght@200..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");

* {
  box-sizing: border-box;
}
* {
  margin: 0;
  padding: 0;
}
.content {
  margin-top: 5rem;
}

/* Extra small devices (phones, 320px and up) */
@media (max-width: 480px) {
  .content {
    margin-top: 5vw;
  }
}
/* Small devices (phones, 480px and up) */
@media (min-width: 480px) and (max-width: 768px) {
  .content {
    margin-top: 3rem;
  }
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 1023px) {
  .content {
    margin-top: 6vw;
  }
}

/* Large devices (desktops, 1024px and up) */
@media (min-width: 1024px) and (max-width: 1279px) {
  .content {
    margin-top: 3vw;
  }
}

/* Extra large devices (1280px and up) */
@media (min-width: 1280px) and (max-width: 1535px) {
  .content {
    margin-top: 5vw;
  }
}
@media (min-width: 1536px) {
  .content {
    margin-top: 3vw;
  }
}
