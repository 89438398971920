.card {
  position: relative;
  background-color: #fff;
  padding: 40px 10px;
  border: 1px solid #eaeaee;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.commitmentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 90%;
  margin: 0 auto;
  margin-top: 5vw;
  margin-bottom: 5vw;
}

.title {
  color: #0e181e;
  font-family: "DM Sans";
  font-size: 4vw;
  font-weight: 700;
  text-align: center;
  margin-bottom: 3vw;
}

.cardsContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2%;
  width: 100%;
}

.card {
  background-color: #fff;
  padding: 40px 10px;
  border-radius: 0px;
  border-bottom: 1px solid #eaeaee;
  border-top: none;
  border-left: none;
  text-align: center;
  box-shadow: none;
  position: relative;
  overflow: hidden;
}

.icon {
  margin: 0;
}

.becauseText {
  color: #5d6266;
  font-family: "DM Sans";
  font-size: 1.5vw;
  font-weight: 400;
  margin-bottom: 1vw;
}

.year {
  color: #333f92;
  font-family: "DM Sans";
  font-size: 2.5vw;
  font-weight: 700;
  margin: 0;
}

.description {
  color: #5d6266;
  font-family: "DM Sans";
  font-size: 1.5vw;
  font-weight: 400;
  margin: 0;
}

/* Breakpoints for responsiveness */

@media (max-width: 1200px) {
  .cardsContainer {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 768px) {
  .content {
    margin-top: 3rem; /* Adjust margin for tablets and smaller devices */
  }

  .title {
    font-size: 6vw;
    margin-bottom: 4vw;
  }

  .cardsContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Display 2 cards per row */
    gap: 0; /* No gaps between cards to handle borders manually */
  }

  .card {
    padding: 1rem;
    border-bottom: 1px solid #979798; /* Bottom border for all cards */
  }
  .card:hover::before,
  .card:hover {
    animation: none;
    border-image-source: none;
    border-width: 0px;
    border-radius: 0px;
  }

  .card:nth-child(odd) {
    border-right: 0px solid #979798; /* Right border for the first column */
  }

  .card:nth-child(even) {
    border-right: none;
    border-left: 0.5px solid #979798; /* Left border for the second column */
  }
  .card:nth-last-child(1), /* Last card */
  .card:nth-last-child(2) {
    border-bottom: none;
  }
  .icon {
    font-size: 8vw;
  }

  .becauseText {
    display: none;
  }

  .description {
    font-size: 2.5vw;
  }

  .year {
    font-size: 3.5vw;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 5.5vw;
    margin-bottom: 5vw;
    margin: 14% 0;
  }
  .cardsContainer {
    grid-template-columns: repeat(2, 1fr);
  }
  .card {
    padding: 10% 5%;
    height: auto;
  }
  .card:hover::before,
  .card:hover {
    animation: none;
    border-image-source: none;
    border-width: 0px;
    border-radius: 0px;
  }

  .card:nth-child(odd) {
    border-right: 0px solid #979798; /* Right border for the first column */
  }

  .card:nth-child(even) {
    border-right: none;
    border-left: 0.5px solid #979798; /* Left border for the second column */
  }
  .card:nth-last-child(1), /* Last card */
  .card:nth-last-child(2) {
    border-bottom: none;
  }
  .icon {
    font-size: 8vw;
  }

  .becauseText {
    display: none;
  }
  .description {
    font-size: 3.7vw;
    color: #5d6266;
    text-align: center;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 2% 0;
  }

  .year {
    font-size: 4.5vw;
  }
}
