@keyframes shine-pulse {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  width: 48%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card:hover::before {
  animation: shine-pulse 4s linear infinite;
}

.card:hover {
  border-radius: 8px;
  border: 1px solid #333f92; /* Changed to a solid color */
}

.wrapper {
  background: #f5f8ff;
}

/* Container */
.container {
  text-align: center;
  padding: 5% 20px;
  max-width: 100%;
  margin: 0 5%;
}

/* Title */
.title {
  font-size: 1.5vw;
  margin-bottom: 10px;
  color: #1a3ddb;
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5vw;
}

/* Subtitle */
.subtitle {
  font-size: 3vw;
  margin-bottom: 20px;
  color: #0e181e;
  text-align: center;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/* Description */
.description {
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: #5d6266;
  text-align: center;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* Cards Container */
.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  margin-top: 6%;
}

/* General Card Styling */
.card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1vw;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  width: 48%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Card Image */
.image {
  width: 15vw;
  height: auto;
  border-radius: 10px;
  background: #2d2d2d;
}

/* Text Container */
.textContainer {
  width: 54%;
}

/* Title inside the product card */
.productTitle {
  font-size: 1.4rem;
  margin-bottom: 10px;
  text-align: left;
  color: #0e181e;
  font-family: Roboto;
  font-weight: 500;
  margin-top: 0;
}

/* Product description inside the card */
.productDescription {
  font-size: 1.1vw;
  margin-bottom: 10px;
  text-align: left;
  color: #5d6266;
  font-family: "DM Sans";
  font-weight: 400;
  line-height: 1.4vw;
}

.readMore {
  text-decoration: none;
  font-weight: bold;
  float: left;
  color: #333f92;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  font-size: 1.1vw;
}

/* Ensure all cards (even and odd) have their image and text in one row */
.cardsContainer .card {
  flex-direction: row; /* All cards should have the image and text in a row */
}

/* Even cards: image on left, text on right */
.cardsContainer .card:nth-child(even) {
  flex-direction: row;
  column-gap: 6%;
}

/* Force the first card to be a row in all views */
.cardsContainer .card:nth-child(odd) {
  flex-direction: row;
  align-items: center;
  flex-direction: row-reverse;
  text-align: left;
  column-gap: 6%;
  /* padding: 0vw; */
}

/* Mobile Version Adjustments */
@media (max-width: 768px) {
  /* All cards become full width on small screens */
  .card {
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 10vw;
    padding: 2vw;
  }

  .container {
    margin-top: 5%;
    padding: 7% 0%;
  }

  /* Image width adjustment for mobile */
  .image {
    /* margin: 0 0 15px 0; */
    width: 40%;
  }

  /* Text Container width for mobile */
  .textContainer {
    width: 100%;
  }

  .title {
    font-size: 3vw;
    color: #1a3ddb;
    text-align: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
  }

  .productTitle {
    font-size: 4.5vw;
    margin: 0;
  }

  .productDescription {
    font-size: 3vw;
    margin: 2% 0;
    margin-bottom: 10px;
    text-align: left;
    color: #5d6266;
    font-family: "DM Sans";
    font-weight: 400;
    line-height: 3.4vw;
  }

  .subtitle {
    font-size: 8vw;
    color: #0e181e;
    text-align: center;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin: 3% 0;
  }

  .description {
    color: #5d6266;
    text-align: center;
    font-family: "DM Sans";
    font-size: 3.5vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 5% 0px;
  }

  .readMore {
    text-decoration: none;
    font-weight: bold;
    float: left;
    color: #333f92;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-size: 3vw;
  }

  .cardsContainer .card:nth-child(1) {
    flex-direction: row-reverse;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    column-gap: 6%;
  }

  /* Image and text side by side */
  .cardsContainer .card:nth-child(1) .image {
    width: 40%;
    /* margin-right: 5%; */
  }

  .cardsContainer .card:nth-child(1) .textContainer {
    width: 55%;
  }
}
@media (max-width: 480px) {
  .content {
    margin-top: 3em; /* Adjust margin for very small devices like phones */
  }
  .cardsContainer .card:nth-child(even) {
    align-items: center;
  }
  .subtitle {
    font-size: 6vw;
  }
  .card {
    padding: 3vw;
  }
}
