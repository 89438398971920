/* WireShotCards.module.css */
* {
  box-sizing: border-box;
}

.cardsContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2%;
  padding: 2% 6%; /* Changed from 40px 118px to 2% 6% */
  background: #f5f8ff;
}

.card {
  height: 15vw;
  padding: 6%; /* Changed from 34px 24px to 2% 1.5% */
  border-radius: 1.5vw; /* Changed from 20px to 1.5vw */
  border: 2px solid #cfcfcf; /* Changed from 1px to 0.1vw */
  background: #fff;
  margin-bottom: 5%;
  position: relative; /* Ensure the pseudo-element is positioned relative to the card */
  overflow: hidden; /* Prevent text overflow */
  transition: border 0.6s ease-in-out; /* Smooth transition for border change */
}

/* Keyframes for shine pulse effect */
@keyframes shine-pulse {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

/* Keyframes for border color animation */
@keyframes border-color-change {
  0% {
    border-color: #333f92;
  }
  25% {
    border-color: #5e78ee;
  }
  50% {
    border-color: #ea5f38;
  }
  75% {
    border-color: #1a3ddb;
  }
  100% {
    border-color: #333f92;
  }
}

.card::before {
  content: "";
  position: absolute;
  top: -200%;
  left: -200%;
  width: 400%;
  height: 400%;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background-size: 200% 200%;
  border-radius: inherit;
  opacity: 0;
  transition: opacity 0.6s ease-in-out;
  animation: shine-pulse 4s ease-in-out infinite; /* Slower shine pulse */
  pointer-events: none; /* Ensure it does not interfere with hover */
}

/* Apply hover effect */
.card:hover::before {
  opacity: 1; /* Make shine effect visible on hover */
}

.card:hover {
  border: 0.2vw solid #333f92; /* Initial border color */
  animation: border-color-change 4s ease-in-out infinite;
}

.cardTitle,
.cardDescription {
  overflow: hidden; /* Prevent text overflow */
}

.cardTitle {
  color: #333f92;
  font-family: Roboto;
  font-size: 1.7vw; /* Changed from 24px to 3vw */
  font-style: normal;
  font-weight: 500;
  line-height: 4vw; /* Adjusted for scaling with viewport width */
  text-align: left;
  margin: 0;
}

.cardDescription {
  color: #5d6266;
  font-family: "DM Sans";
  font-size: 1vw; /* Changed from 16px to 2vw */
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  margin: 0;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .cardsContainer {
    grid-template-columns: repeat(2, 1fr);
    padding: 8% 6%;
    grid-gap: 0%;
  }

  .cardTitle {
    font-weight: 600;
    line-height: 7vw; /* 300% */
    font-size: 3.5vw; /* Adjusted for medium screens */
  }

  .cardDescription {
    line-height: 4vw;
    font-size: 3.5vw; /* Adjusted for medium screens */
  }
  .card {
    height: 33vw;
    border-radius: 20px;
  }
}

@media (max-width: 480px) {
  .cardsContainer {
    grid-template-columns: 1fr;
  }

  .cardTitle {
    font-weight: 600;
    line-height: 12vw; /* 300% */
    font-size: 5vw; /* Adjusted for small screens */
  }

  .cardDescription {
    line-height: normal;
    font-size: 3.5vw; /* Adjusted for small screens */
  }

  .card {
    height: max-content;
    border-radius: 20px;
  }
}
