.aboutHero {
  text-align: center;
  margin: 9vw 5vw 5vw 5vw; /* Margin relative to viewport width */
}

.topList {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 3vw; /* Gap relative to viewport width */
  margin: 0;
  color: #333f92;
  font-family: "DM Sans";
  font-size: 2vw; /* Font size relative to viewport width */
  font-style: normal;
  font-weight: 700;
  line-height: 2vw; /* Line height relative to font size */
}

.mainHeading {
  margin: 0;
  color: #0f153d;
  text-align: center;
  font-family: Roboto;
  font-size: 4.5vw; /* Font size relative to viewport width */
  font-style: normal;
  font-weight: 700;
  line-height: 6vw; /* Line height relative to font size */
  margin-top: 2vw; /* Margin-top relative to viewport width */
}

.description {
  max-width: 80vw; /* Max-width relative to viewport width */
  color: #5d6266;
  text-align: center;
  font-family: "DM Sans";
  font-size: 1.7vw; /* Font size relative to viewport width */
  font-style: normal;
  font-weight: 400;
  line-height: 2vw; /* Line height relative to font size */
  margin: 2vw auto 4vw auto; /* Margin relative to viewport width */
}

.imageContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  height: auto; /* Auto height to maintain aspect ratio */
  padding: 2vw 0; /* Padding relative to viewport width */
}

.heroImage {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  /* max-width: 90vw; */
  border-radius: 12px;
}

@media (max-width: 768px) {
  /* .aboutHero {
    margin: 5vw 5vw;
  } */

  .wrapper {
    margin-top: 19vw;
  }
  .topList {
    flex-direction: row;
    gap: 1vw; /* Gap relative to viewport width */
    font-size: 4vw; /* Font size relative to viewport width */
    line-height: 4vw; /* Line height relative to font size */
  }

  .mainHeading {
    font-size: 8vw; /* Font size relative to viewport width */
    line-height: 9vw; /* Line height relative to font size */
    margin-top: 2vw;
    margin: 4% 0;
  }

  .description {
    font-size: 3vw; /* Font size relative to viewport width */
    line-height: normal; /* Line height relative to font size */
    max-width: 100%; /* Max-width relative to viewport width */
    margin: 1.5vw auto 3vw auto; /* Margin relative to viewport width */
  }

  .heroImage {
    max-width: 100%;
    height: 34vw;
    border-radius: 8px;
    flex-shrink: 0;
  }
}

@media (max-width: 480px) {
  .description {
    line-height: 4vw;
    text-align: justify;
  }
}
