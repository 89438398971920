/* Base styles */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7vw 5vw;
  background: #c9d7f4;
}

.leftCol {
  flex: 1;
  padding-right: 8%;
  margin-bottom: 2vw;
}

.rightCol {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.benefitRow {
  display: flex;
  align-items: center;
  margin-bottom: 2vw;
  justify-content: flex-start;
}

.icon img {
  width: 2.5vw;
  margin-right: 2vw;
}

.headingText {
  color: #060a28;
  font-family: Roboto, sans-serif;
  font-size: 4vw;
  font-weight: 700;
  margin-bottom: 2vw;
}

.benefitText {
  color: #0e181e;
  font-family: Inter, sans-serif;
  font-size: 1.4vw;
  font-weight: 600;
  line-height: 140%;
  margin: 0;
  align-self: flex-start;
}

.desc {
  color: #060a28;
  font-family: "DM Sans", sans-serif;
  font-size: 1.3vw;
  font-weight: 400;
  line-height: 150%;
  margin-bottom: 2vw;
  text-align: justify;
}

.button {
  padding: 1vw 3vw;
  background: #c9d7f4;
  color: #060a28;
  border: 1px solid #060a28;
  cursor: pointer;
  font-size: 1.5vw;
  border-radius: 2vw;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 0.5vw;
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  line-height: 1.2;
}

/* Responsive design for larger screens */
@media (min-width: 768px) {
  .container {
    flex-direction: row;
  }

  .leftCol {
    margin-bottom: 0;
  }
}

/* Responsive design for smaller screens */
@media (max-width: 767px) {
  .container {
    margin-top: 5%;
    padding: 5vw 5vw;
  }
  .leftCol {
    padding: 0;
  }
  .headingText {
    font-size: 6vw;
    color: #333f92;
    text-align: center;
    font-family: Roboto;
    /* font-size: 24px; */
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
  }

  .desc {
    color: #5d6266;
    text-align: center;
    font-family: "DM Sans";
    font-size: 3vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #0e181e;
  }
  .btnDiv {
    margin: 8% 0;
    display: flex;
    justify-content: center;
  }
  .button {
    font-size: 3.5vw;
    padding: 3%;
    border-radius: 7vw;
    background: #c9d7f4;
  }

  .benefitRow {
    margin-bottom: 1.5vw;
  }

  .icon {
    font-size: 2.5vw;
  }
  .benefitText {
    font-size: 3vw;
    margin: 2% 0;
  }
  .icon img {
    width: 8vw;
    margin-right: 6vw;
  }
}
@media (max-width: 480px) {
  .headingText {
    margin-bottom: 4vw;
  }
  .button {
    font-size: 3.5vw;
    padding: 3% 4%;
    line-height: 18px;
    border-radius: 7vw;
    background: #c9d7f4;
  }
}
