.containerWrapper {
  background: #f5f8ff;
  padding: 5% 8vw; /* Using percentage and viewport width for padding */
}
.header {
  color: #333f92;
  font-family: Roboto;
  font-size: 3vw;
  font-style: normal;
  font-weight: 700;
  line-height: 100px; /* 208.333% */
}
.container {
  display: flex;
  flex-wrap: wrap;
  gap: 2% 2%; /* Adjusted gap to have spacing between both rows and columns */

  justify-content: center;
}

.card {
  position: relative; /* Position relative to enable absolute positioning of pseudo-element */
  flex: 1;
  min-width: 20vw; /* Using viewport width for minimum width */
  max-width: 30vw; /* Using viewport width for maximum width */
  padding: 2%; /* Using percentage for padding */
  text-align: center;
  border-radius: 1vw; /* Using viewport width for border-radius */
  border: 1px solid #cfcfcf;
  background: #fff;
  margin-bottom: 2%; /* Adding margin to create space between rows */
  overflow: hidden; /* Ensures pseudo-element does not overflow the card */
  text-wrap: nowrap;
}

.specification {
  color: #333f92;
  text-align: center;
  font-family: Roboto, sans-serif; /* Added fallback font */
  font-size: 2vw; /* Using viewport width for font size */
  font-style: normal;
  font-weight: 500;
  margin: 0;
}

.fullWidthCard {
  flex: 0 0 100%;
  justify-content: center;
  /* max-width: 100%; */
}

@media (max-width: 768px) {
  .header {
    font-size: 5vw;
    text-align: center;
    margin-top: 0;
  }
  .container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .card {
    max-width: 100%;
    padding: 2vw;
    border-radius: 9.646px;
    border: 0.482px solid #cfcfcf;
    background: #fff;
  }
  .specification {
    color: #333f92;
    text-align: center;
    font-family: Roboto, sans-serif; /* Added fallback font */
    font-size: 3vw; /* Using viewport width for font size */
    font-style: normal;
    font-weight: 500;
    margin: 0;
  }
}

@media (max-width: 480px) {
  .card {
    max-width: 100%;
    padding: 2vw;
    border-radius: 9.646px;
    border: 0.482px solid #cfcfcf;
    background: #fff;
    height: max-content;
  }
  .container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .header {
    font-size: 6vw;
    line-height: 15vw;
  }
}
