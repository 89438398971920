.privacyPolicy {
  text-align: center; /* Ensures all text is centered */
  width: 100%;
  max-width: 70vw; /* Use viewport width for max-width */
  margin-top: 10vw;
  margin-left: auto; /* Center the container horizontally */
  margin-right: auto; /* Center the container horizontally */
  margin-bottom: 10vw;
}

.topText {
  color: #6d7a65;
  font-family: Poppins;
  font-size: 1.5vw; /* Use viewport width for font size */
  font-style: normal;
  font-weight: 500;
  line-height: 1.125; /* Maintain line-height without fixed units */
  letter-spacing: 0.1vw; /* Adjust spacing using viewport width */
  text-transform: uppercase;
}

.heading {
  color: #333f92;
  font-family: Poppins;
  font-size: 5vw; /* Use viewport width for font size */
  font-style: normal;
  font-weight: 600;
  line-height: 1.5; /* Maintain line-height without fixed units */
  text-transform: capitalize;
  margin: 0;
}

.text {
  color: #4e4e4e;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: 1.5; /* Maintain line-height without fixed units */
  text-transform: capitalize;
  margin: 0 auto; /* Center the text block */
  max-width: 70vw; /* Ensure the text block doesn't stretch too wide */

  font-size: 1.4vw;
  margin-bottom: 1vw;
  color: #5d6266;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.effectiveDate,
.subheading {
  font-size: 1.5vw; /* Use viewport width for font size */
  margin-bottom: 2vh; /* Use viewport height for margin-bottom */

  color: #000;
  font-family: Poppins;
  /* font-size: 24px; */
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 36px */
  text-transform: capitalize;
}

.section {
  margin-top: 4vh; /* Use viewport height for top margin */
}

.date {
  align-items: center; /* Center the text within the flex container */
  margin-top: 3vh; /* Use viewport height for top margin */
  color: #000;
  font-family: Poppins;
  font-size: 2vw; /* Use viewport width for font size */
  font-style: normal;
  font-weight: 400;
  line-height: 1.5; /* Maintain line-height without fixed units */
  text-transform: capitalize;
}

.sectionHeading {
  color: #2b3029;
  font-family: Poppins;
  font-size: 3vw; /* Use viewport width for font size */
  font-style: normal;
  font-weight: 600;
  line-height: 1.5; /* Maintain line-height without fixed units */
  text-transform: capitalize;
  margin-bottom: 4vh; /* Use viewport height for margin-bottom */
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .privacyPolicy {
    max-width: 90%;
    margin: 3vh auto;
    padding: 0 4vw; /* Add some padding for smaller screens */
  }

  .text {
    margin: 0 auto;
    max-width: 90vw;
  }

  .heading {
    font-size: 4vw;
  }
  .effectiveDate {
    font-size: 2vw;
  }
  .date {
    font-size: 1.8vw;
  }

  .sectionHeading {
    font-size: 2.8vw;
  }
}

@media (max-width: 768px) {
  .privacyPolicy {
    margin: 21vw auto;
    padding: 0 3vw; /* Less padding on very small screens */
  }

  .text {
    margin: 0 auto;
    max-width: 95vw;
    font-size: 2vw;
  }

  .heading {
    font-size: 5vw;
    margin: 2% 0;
  }

  .date {
    font-size: 1.5vw;
  }

  .sectionHeading {
    font-size: 3vw;
  }

  .topText {
    font-size: 2vw;
  }
}

@media (max-width: 480px) {
  .privacyPolicy {
    max-width: 100%;
    margin: 21vw auto;
    padding: 0 5%;
  }

  .text {
    margin: 0 auto;
    max-width: 100vw;
    font-size: 1.5vw;

    font-size: 3.5vw;
    line-height: 6vw;
    text-align: justify;
  }
  .heading {
    font-size: 8vw;
    margin: 4% 0;
  }

  .date {
    font-size: 1.3vw;
  }
  .sectionHeading {
    font-size: 4.2vw;
  }
  .topText {
    font-size: 3.5vw;
  }
  .para {
    font-size: 3.5vw;
    line-height: 6vw;
    text-align: justify;
  }
  .effectiveDate,
  .subheading {
    color: #000;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 36px */
    text-transform: capitalize;
    font-size: 4vw; /* Use viewport width for font size */
    margin-bottom: 2vh; /* Use viewport height for margin-bottom */
  }
}
