.blogContainer {
  padding-bottom: 80px;
  background: #060a28;
  display: flex;
  justify-content: center;
}

.blogWrapper {
  background: #060a28;
  margin: 0 5%; /* Adjusted to prevent overflow */
  max-width: 100%; /* Added max-width to control overflow */
  box-sizing: border-box; /* Ensure padding does not affect width */
}

.headerRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 53px;
  gap: 12px;
}

.headerCol {
  margin-top: 106px;
  width: 60%;
}

.headerText {
  margin: 0;
  color: #fff;
  font-family: "Plus Jakarta Sans";
  font-size: 3vw;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1.44px;
}

.descCol {
  width: 35%; /* Adjusted to ensure it fits within the blogWrapper */
  /* text-align: right; */
  margin-top: 80px;
}

.paraText {
  color: #fff;
  font-family: "Plus Jakarta Sans";
  font-size: 1.1vw;
  font-style: normal;
  font-weight: 500;
  text-align: left;
  line-height: 180%; /* 21.6px */
  margin: 8% 0;
}

.seeMoreButton {
  margin-top: 15%;
  width: 150px;
  padding: 1vw 2vw;
  justify-content: center;
  cursor: pointer;
  border-radius: 50px;
  border: 1px solid #fff;
  background: #060a28;
  color: #fff;
  font-family: Poppins;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  letter-spacing: -0.32px;
}

.cardsRow {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center; /* Center align cards in row */
}

.card {
  flex: 1;
  min-width: 280px; /* Adjusted for better fit */
  max-width: calc(33.333% - 1rem); /* Ensure cards fit within the row */
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 22px;
  flex-direction: column;
  align-items: flex-start;
}

.cardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 17px;
}

.circleDot {
  width: 8px;
  height: 8px;
  background-color: #007bff;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.blogHeading {
  margin: 0;
  color: #010205;
  font-family: "Plus Jakarta Sans";
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 27.829px */
  letter-spacing: -0.557px;
}

.blogImage {
  width: 100%;
  height: auto;
  margin-top: 22px;
  margin-bottom: 22px;
  border-radius: 8px;
}

.shortText {
  width: 69%; /* Full width for short text */
  height: fit-content;
  color: #878c91;
  text-overflow: ellipsis;
  font-family: "Plus Jakarta Sans";
  font-size: 1vw;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 21.312px */
  margin: 0;
  text-align: left;
}

.cardFooter {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.readMore {
  flex: 1;
}

.time {
  overflow: hidden;
  color: #878c91;
  text-overflow: ellipsis;
  font-family: "Plus Jakarta Sans";
  font-size: 13.32px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 21.312px */
}

.readMoreButton {
  background-color: #fff;
  color: white;
  padding: 0.5rem;
  border-radius: 66.6px;
  border: 0.951px solid #010205;
  cursor: pointer;
  display: flex;
  width: 83px;
  align-items: center;
  gap: 8px;
  justify-content: center;
}

.arrowImg {
  width: 22.834px;
  height: 22.834px;
}
.slick-prev,
.slick-next {
  display: none !important;
}

/* Responsive Layout Adjustments */

@media (max-width: 1200px) {
  .slick-prev,
  .slick-next {
    display: none !important;
  }

  .blogWrapper {
    margin: 2%;
  }

  .headerCol {
    width: 100%; /* Full width on smaller screens */
    margin-top: 20px; /* Adjust margin for smaller screens */
  }

  .descCol {
    width: 100%; /* Full width on smaller screens */
    text-align: center; /* Center align text on smaller screens */
    margin-top: 20px; /* Adjust margin for smaller screens */
  }

  .cardsRow {
    gap: 1rem; /* Ensure cards have space on medium screens */
  }

  .card {
    width: 100%; /* Full width on smaller screens */
    padding: 1rem; /* Adjust padding for smaller screens */
  }
}

@media (max-width: 992px) {
  .headerText {
    font-size: 5vw; /* Reduce font size for smaller screens */
    text-align: center;
  }

  .paraText {
    margin-bottom: 5%;
    text-align: center;
    margin-top: 0;
    font-size: 2.5vw; /* Reduce font size for smaller screens */
  }

  .seeMoreButton {
    padding: 1% 3%;
    width: 36%; /* Reduce button width for smaller screens */
    font-size: 2.5vw; /* Reduce font size for button */
  }

  .card {
    width: calc(50% - 1rem); /* 2 cards per row */
  }
  .headerRow {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .slick-prev,
  .slick-next {
    display: none !important;
  }
  /* Customize slick dots */
  .slick-dots {
    bottom: 10px; /* Adjust the position from the bottom */
  }

  .slick-dots li {
    margin: 0 5px; /* Adjust spacing between dots */
  }

  .slick-dots li button {
    background-color: #007bff; /* Dot color */
    border-radius: 50%; /* Make dots round */
    width: 12px; /* Adjust dot size */
    height: 12px; /* Adjust dot size */
    opacity: 1; /* Make dots fully visible */
  }

  .slick-dots li.slick-active button {
    background-color: #fff; /* Color for the active dot */
  }

  .blogContainer {
    padding-bottom: 16px;
    margin-top: 0;
    padding: 0 4%;
  }

  .headerRow {
    flex-direction: column; /* Stack header content vertically */
    margin-top: 16px;
  }

  .headerCol,
  .descCol {
    width: 100%; /* Full width for both columns */
    margin-top: 0; /* Remove top margin */
    text-align: center; /* Center align text */
  }

  .cardsRow {
    flex-direction: column; /* Stack cards vertically */
    align-items: center;
  }

  .card {
    width: 100%; /* Full width for each card */
    padding: 1rem; /* Adjust padding for smaller screens */
    margin-bottom: 1rem; /* Add margin between cards */
    border-radius: 13.845px;
    min-width: 100%;
  }

  .blogHeading {
    font-size: 3vw; /* Adjust font size for headings */
    margin-bottom: 23px;
    text-align: left;
  }

  .headerText {
    color: #fff;
    text-align: center;
    font-size: 3vw;
    line-height: 130%; /* 20.8px */
    letter-spacing: -0.48px;
    margin-bottom: 3%;
  }

  .paraText {
    color: #fff;
    text-align: center;
    font-size: 2.5vw;
    line-height: 3.5vw;
    margin: 6% 0;
    margin-top: 0;
  }

  .readMoreButton {
    width: 11%;
    padding: 2%;
    font-size: 3vw;
  }

  .arrowImg {
    width: 16px; /* Adjust arrow icon size */
    height: 16px; /* Adjust arrow icon size */
  }

  .blogImage {
    width: 100%;
    height: auto;
    margin: 0;
    border-radius: 13.845px;
  }
  .shortText {
    font-size: 2vw;
    margin-top: 3%;
    width: 65%;
  }
}

@media (max-width: 576px) {
  .slick-prev,
  .slick-next {
    display: none !important;
  }

  .headerText {
    font-size: 7vw;
    line-height: 130%; /* 20.8px */
    letter-spacing: -0.48px;
    margin-top: 5%;
  }

  .paraText {
    font-size: 3vw;
    line-height: 4.3vw;
    margin-bottom: 5%;
  }

  .seeMoreButton {
    display: none; /* Hide button on very small screens */
  }

  .card {
    width: 100%; /* Full width for cards */
    padding: 5.5vw;
  }

  .shortText {
    margin: 4% 0;
    font-size: 3vw;
  }
  .headerRow {
    gap: 0;
    margin: 5% 0;
  }
}
