.aboutHero {
  text-align: center;
  margin: 9vw 10% 7% 10%; /* Changed from px to % */
}

.heading {
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 2vw; /* Changed from rem to vw */
  margin: 0;
  color: #333f92;
  text-align: center;
  font-family: "DM Sans";
  font-size: 2vw; /* Changed from px to vw */
  font-style: normal;
  font-weight: 700;
  line-height: 2vw; /* Adjusted for consistency */
}

.mainHeading {
  margin: 0;
  color: #0f153d;
  text-align: center;
  font-family: Roboto;
  font-size: 3vw; /* Changed from px to vw */
  font-style: normal;
  font-weight: 700;
  line-height: 4vw; /* Adjusted for consistency */
  margin-top: 3vw; /* Changed from px to vw */
}

.imageContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  height: auto; /* Changed from fixed px height to auto */
  flex-shrink: 0;
}

.heroImage {
  width: 100%;
  height: auto;
  max-width: 100%; /* Changed from px to % */
  margin-top: 3vw; /* Changed from px to vw */
  border-radius: 1vw; /* Changed from px to vw */
}

@media (max-width: 768px) {
  .aboutHero {
    margin: 20vw 5% 7% 5%;
  }
  .heading {
    font-size: 5vw;
  }
  .mainHeading {
    margin-top: 5vw;
    font-size: 5vw;
    line-height: 5vw;
  }

  .heroImage {
    width: 100%;
    border-radius: 8px;
    height: 36vw;
  }
}
