.container {
  display: flex;
  flex-wrap: wrap;
  margin: 9vw 5vw 5vw 5vw; /* Using vw for margin */
}

.leftCol {
  flex: 1;
  max-width: 50%;
  padding-right: 2vw; /* Using vw for padding */
}

.rightCol {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.qualities {
  color: #333f92;
  font-family: "DM Sans";
  font-size: 1.5vw; /* Using vw for font size */
  font-weight: 700;
  line-height: 1.25vw;
  margin-bottom: 1.5vw;
}

.heading {
  color: #0f153d;
  font-family: Roboto;
  font-size: 4.6vw;
  font-weight: 700;
  line-height: 5.5vw;
  margin-bottom: 2vw;
  margin-top: 2vw;
}

.description {
  color: #5d6266;
  font-family: "DM Sans";
  font-size: 1.5vw; /* Using vw for font size */
  line-height: 2.4vw; /* Using vw for line height */
  text-align: justify;
}

.image {
  max-width: 80vw; /* 80% of the viewport width */
  /* max-width: 447px; */
  height: auto; /* Keeps the image's aspect ratio */
  border-radius: 12px;
  border: 0.4px solid #fff;
  flex-shrink: 0;
}

.fullWidthImageWrapper {
  width: 100%;
  margin-top: 2vw; /* Using vw for margin-top */
}

.fullWidthImage {
  width: 100%;
  height: 32vw;
  object-fit: cover;
  border-radius: 12px;
  background: #2d2d2d;
  flex-shrink: 0;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    margin: 21vw 5vw 2vw 5vw; /* Adjusted margin for smaller screens */
  }

  .leftCol {
    max-width: 100%;
    padding-right: 0;
    margin-bottom: 2vw;
    text-align: center;
  }
  .rightCol {
    max-width: 100%;
    padding-right: 0;
    margin-bottom: 2vw;
    justify-content: center;
    text-align: center;
  }
  .qualities {
    color: #333f92;
    text-align: center;
    font-family: "DM Sans";
    font-size: 3vw;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 166.667% */
  }
  .heading {
    font-size: 8vw; /* Larger font size for smaller screens */
    line-height: 9vw;
  }

  .description {
    font-size: 3vw; /* Larger font size for smaller screens */
    line-height: 4vw;
  }

  .fullWidthImageWrapper {
    margin-top: 2vw;
  }

  .image {
    max-width: 100%; /* Full width on smaller screens */
    margin: 5% 0;
  }
}
@media (max-width: 480px) {
}
