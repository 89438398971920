.pagination {
  display: flex;
  justify-content: center;
  margin-top: 2vw;
}

.paginationList {
  list-style: none;
  display: flex;
  gap: 1vw;
}

.pageItem {
  display: inline-block;
}

.pageLink {
  color: #010205;
  background-color: #fff;
  border: 0.1vw solid #cfcfcf;
  padding: 0.5vw 1vw;
  border-radius: 1vw;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.pageLink:hover {
  background-color: #010205;
  color: #fff;
}

.active {
  background-color: #007bff;
  color: #fff;
}

@media (max-width: 480px) {
  .paginationList {
    gap: 3vw;
  }
  .active {
    background-color: #007bff;
    color: #fff;
    padding: 1vw;
    width: 7vw;
    border-radius: 3vw;
    font-size: 4vw;
  }
  .pageLink {
    font-size: 4vw;
    border-radius: 3vw;
    padding: 1vw;
    width: 7vw;
  }
}
