.blogContainer {
  padding-bottom: 5vw; /* Adjusted padding with vw */
  background: #f2f4ff;
  margin-top: 5vw; /* Adjusted margin with vw */
  display: flex;
  justify-content: center;
}

.blogWrapper {
  position: relative; /* Ensure ::before is positioned correctly */
  background: #f2f4ff;
  margin: 2vw 5vw; /* Adjusted margin with vw */
  max-width: 90vw; /* Max width with vw to control overflow */
  box-sizing: border-box; /* Ensure padding does not affect width */
}

/* search */
.searchContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2vw;
}

.searchBox {
  padding: 1vw;
  font-size: 1.5vw;
  border: 0.1vw solid #ddd;
  border-radius: 1vw;
  width: 20vw;
  border-radius: 2vw;
  border: 1px solid #333f92;
  background: #fff;

  color: #cfcfcf;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 16px */
}
.searchBox:focus,
.searchBox:active {
  border: 1px solid #333f92;
  outline: none;
  color: #000;
}
.cardsRow {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Ensure 3 cards per row */
  gap: 2vw; /* Adjusted gap with vw */
  margin-top: 5vw; /* Adjusted margin with vw */
}

.card {
  position: relative; /* Ensure ::before is positioned correctly */
  border: 0.1vw solid #ddd;
  border-radius: 1vw;
  overflow: hidden;
  background-color: #fff;
  display: flex;
  padding: 2vw;
  flex-direction: column;
  align-items: flex-start;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effect */
}

.cardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1vw;
}

.circleDot {
  width: 1vw;
  height: 1vw;
  background-color: #007bff;
  border-radius: 50%;
  margin-right: 0.5vw;
}

.blogHeading {
  color: #010205;
  font-family: "DM Sans";
  font-size: 1.5vw;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: -0.1vw;
  margin: 0;
}

.blogImage {
  width: 100%;
  height: auto;
  margin-top: 1vw;
  margin-bottom: 1vw;
  border-radius: 1vw;
}

.shortText {
  width: 64%;
  height: auto;
  flex-shrink: 0;
  overflow: hidden;
  color: #878c91;
  text-overflow: ellipsis;
  font-family: "Plus Jakarta Sans";
  font-size: 1.2vw;
  font-weight: 500;
  line-height: 1.6;
  margin: 0;
}

.cardFooter {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.readMore {
  flex: 1;
}

.time {
  overflow: hidden;
  color: #878c91;
  text-overflow: ellipsis;
  font-family: "Plus Jakarta Sans";
  font-size: 1.2vw; /* Adjusted font size with vw */
  font-weight: 500;
  line-height: 1.6;
}

.readMoreButton {
  background-color: #fff;
  color: #010205;
  padding: 0.5vw;
  border-radius: 1.5vw;
  border: 0.1vw solid #010205;
  cursor: pointer;
  display: flex;
  width: 5vw; /* Adjusted width with vw */
  align-items: center;
  gap: 1vw;
  justify-content: center;
}

.arrowImg {
  width: 2vw; /* Adjusted width with vw */
  height: 2vw; /* Adjusted height with vw */
}

/* Responsive Layout Adjustments */

@media (max-width: 1200px) {
  .blogWrapper {
    margin: 2vw; /* Adjusted margin with vw */
  }

  .cardsRow {
    grid-template-columns: repeat(3, 1fr); /* Maintain 3 cards per row */
    gap: 1.5vw; /* Adjusted gap with vw */
  }

  .card {
    padding: 2vw;
  }
}

@media (max-width: 992px) {
  .cardsRow {
    grid-template-columns: repeat(2, 1fr); /* 2 cards per row */
  }
}

@media (max-width: 768px) {
  .time {
    font-size: 4vw;
  }
  .blogWrapper {
    margin: 2vw;
  }

  .blogContainer {
    padding-bottom: 2vw;
    background: #f5f8ff;
    margin-top: 2vw;
    display: flex;
    justify-content: center;
  }

  .cardsRow {
    grid-template-columns: 1fr; /* 1 card per row */
    gap: 2vw; /* Adjusted gap with vw */
  }

  .card {
    width: 100%;
    padding: 2vw;
    margin-bottom: 2vw;
  }

  .cardFooter {
    width: 100%;
    margin-top: 2vw;
  }

  .blogHeading {
    font-size: 4vw;
    margin-bottom: 2vw;
  }

  .headerText {
    color: #fff;
    text-align: center;
    font-family: "Plus Jakarta Sans";
    font-size: 4vw;
    font-weight: 600;
    line-height: 1.3;
    letter-spacing: -0.1vw;
  }

  .paraText {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 2vw;
    font-weight: 400;
    line-height: 1.34;
  }

  .shortText {
    width: 67%;
    font-size: 3vw;
  }

  .readMoreButton {
    width: 8vw;
    padding: 1.5vw;
  }

  .arrowImg {
    width: 3vw;
    height: 3vw;
  }

  .blogImage {
    width: 100%;
    margin: 0;
  }
  .searchBox {
    font-size: 3vw;
    width: 40vw;
  }
}

@media (max-width: 576px) {
  .blogWrapper {
    margin: 2vw;
  }

  .headerText {
    font-size: 4vw;
  }

  .paraText {
    font-size: 2vw;
  }

  .seeMoreButton {
    width: 20vw;
    padding: 1.5vw 3vw;
    font-size: 2vw;
  }

  .card {
    width: 100%;
    padding: 1vw;
    border-radius: 13.845px;
    background: #fff;
    padding: 5vw 4vw;
  }

  .blogHeading {
    font-size: 4vw;
    border-radius: 5px;
    text-align: left;
  }

  .shortText {
    font-size: 3.2vw;
    text-align: left;
  }

  .readMoreButton {
    width: 5vw;
    padding: 2vw 6vw;
    border-radius: 21px;
  }

  .arrowImg {
    width: 4vw;
    height: 4vw;
  }
  .searchBox {
    font-size: 3vw;
    width: 40vw;
  }
}
