.card {
  position: relative;
  background: #f2f5fc;
  padding: 2vw;
  border-radius: 24px;
  border: 1px solid #eaeaee;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.container {
  padding: 4% 5%; /* Adjusted for better responsiveness */
  max-width: 100%; /* Ensures it scales with screen size */
  margin: 0 auto;
}

.header {
  margin-bottom: 3%;
}

.title {
  margin-bottom: 0;
  text-align: left;
  color: #333f92;
  font-family: Roboto;
  font-size: 4vw; /* Responsive font size */
  font-weight: 700;
  line-height: 1.5em; /* Use `em` for line-height */
}

.description {
  width: 70%; /* Relative width for better scaling */
  color: #5d6266;
  font-family: "DM Sans";
  font-size: 1.5vw; /* Responsive font size */
  line-height: 1.5em;
  text-align: left;
  margin-top: 0;
  margin-bottom: 3%;
}

.cardsContainer {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(30%, 1fr)
  ); /* Responsive grid */
  gap: 2%;
  row-gap: 32px;
}

.icon {
  font-size: 3vw; /* Responsive icon size */
  text-align: left;
}

.cardTitle {
  color: #0e181e;
  font-family: Roboto;
  font-size: 1.8vw; /* Responsive font size */
  font-weight: 600;
  line-height: 1.5em;
  margin-bottom: 0;
  margin-top: 0;
  text-align: left;
}

.cardDescription {
  color: #5d6266;
  font-family: "DM Sans";
  font-size: 1.2vw; /* Responsive font size */
  line-height: 1.5em;
  text-align: left;
  margin: 0;
}

@media (max-width: 1200px) {
  .title {
    font-size: 3.5vw;
  }

  .description {
    font-size: 1.8vw;
  }

  .cardTitle {
    font-size: 2.2vw;
  }

  .cardDescription {
    font-size: 1.1vw;
  }
}

@media (max-width: 992px) {
  .title {
    font-size: 4vw;
  }

  .description {
    font-size: 2vw;
  }

  .cardTitle {
    font-size: 2.5vw;
  }

  .cardDescription {
    font-size: 1.4vw;
  }
  .card {
    border-radius: 10px;
    height: auto;
    padding: 15px;
  }
}

@media (max-width: 768px) {
  .title {
    font-size: 5vw;
    text-align: center;
  }

  .description {
    width: 100%;
    font-size: 2.7vw;
    margin-top: 2%;
    text-align: center;
    margin-bottom: 6%;
  }
  .icon {
    font-size: 3vw; /* Responsive icon size */
    text-align: center;
  }
  .cardTitle {
    font-size: 3vw;
    text-align: center;
  }

  .cardDescription {
    font-size: 2vw;
    text-align: center;
  }

  .cardsContainer {
    grid-template-columns: repeat(2, 1fr);
    gap: 2%; /* Adjust gap as needed */
  }
  .card {
    border-radius: 10px;
    background: #f2f5fc;
    /* height: max-content; */
    padding: 4vw 2vw;
  }
}

@media (max-width: 576px) {
  .title {
    font-size: 8vw;
    text-align: center;
  }
  .icon {
    font-size: 3vw; /* Responsive icon size */
    text-align: center;
  }
  .description {
    width: 100%;
    font-size: 3.3vw;
    text-align: center;
    margin-top: 4%;
  }

  .cardTitle {
    margin-top: 3%;
    font-size: 4vw;
    text-align: center;
  }

  .cardDescription {
    font-size: 3vw;
    text-align: center;
    margin: 7% 0;
  }

  .cardsContainer {
    grid-template-columns: repeat(2, 1fr); /* 2 cards per row */
    gap: 2%; /* Adjust gap as needed */
  }
  .card {
    border-radius: 10px;
    background: #f2f5fc;
  }
}
