.sQualityContainer {
  text-align: center;
  padding: 5% 8vw;
  background: #f5f8ff;
}

.title {
  margin-bottom: 1rem;
  color: #333f92;
  text-align: center;
  font-family: "DM Sans", sans-serif;
  font-size: 4vw; /* relative font size */
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.description {
  margin-bottom: 2rem;
  max-width: 80%; /* relative width */
  margin: 0 auto;
  color: #5d6266;
  text-align: center;
  font-family: "DM Sans", sans-serif;
  font-size: 1.4vw; /* relative font size */
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2vw; /* relative spacing */
  margin-top: 5vw; /* relative margin */
}

.card {
  position: relative;
  padding: 2vw; /* relative padding */
  text-align: center;
  border: 1px solid #eaeaee;
  flex-basis: calc(33% - 2vw); /* relative width */
  border-radius: var(--Num-radius-lg, 12px);
  background: var(--Bg-white, #fff);
  overflow: hidden; /* Ensure shine effect is contained */
  transition: border-color 0.6s ease-in-out; /* Smooth transition for border color */
}

.icon {
  font-size: 1vw; /* relative font size */
  margin-bottom: 1rem;
  color: #007bff;
}

.cardText {
  text-align: center;
  font-size: 1.5vw; /* relative font size */

  color: var(--Text-color-base, #14181f);
  text-align: center;
  font-family: "Plus Jakarta Sans";
  /* font-size: 18px; */
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 25.2px */
}

@media (max-width: 768px) {
  .cardContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .card {
    flex-basis: calc(50% - 2vw); /* relative width */
    padding: 7vw 3vw;
  }

  .title {
    font-size: 6vw; /* relative font size */
  }

  .description {
    font-size: 2.5vw; /* relative font size */
    max-width: 100%;
    /* margin: 0; */
  }

  .cardText {
    font-size: 3vw;

    color: var(--Text-color-base, #14181f);
    text-align: center;
    font-family: "Plus Jakarta Sans";
    /* font-size: 14px; */
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
  }
}

@media (max-width: 480px) {
  .card {
    flex-basis: 100%; /* full width */
    padding: 7vw 3vw;
  }

  .title {
    font-size: 6vw; /* relative font size */
    line-height: 14vw;
  }

  .description {
    font-size: 3vw; /* relative font size */
    max-width: 100%;
    margin: 0 0;
    line-height: 4vw;
    /* margin: 0; */
  }
}
