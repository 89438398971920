.card {
  padding: 3vw 3vw;
  border-radius: 10px;
  text-align: center;
  background: #f5f8ff;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25);
}

.logoDiv {
  display: flex;
  flex-direction: column;
}

.logo {
  width: 40px;
  margin-bottom: 3vw;
  text-align: center;
}

.squareImg {
  width: 100%;
  max-width: 382px;
  height: auto; /* Adjust height to maintain aspect ratio */
  flex-shrink: 0;
  margin-bottom: 20px;
  border-radius: 15.079px;
  background: #cfcfcf;
}

.infoRow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 2vw 0;
  gap: 16px;
}

.icon {
  margin-right: 10px;
  color: #4a4a4a;
}
.infoCard {
  border-radius: 10px;
  background: #fff;
  padding: 2vw;
}
.infoText {
  color: #2d2d2d;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
}
.mapDiv {
  width: 30vw;
  height: 20vw;
  margin-bottom: 4%;
  /* border: none; */
}
/* Responsive styles for smaller devices */
@media (max-width: 768px) {
  .card {
    padding: 5vw 6vw;
  }

  .logo {
    width: 30px;
    margin-bottom: 5vw;
  }

  .squareImg {
    width: 100%;
    max-width: 300px;
    border-radius: 15.079px;
    background: #cfcfcf;
    height: auto;
  }

  .infoRow {
    flex-direction: row;
    align-items: flex-start; /* Align text and icons */
    gap: 8px;
  }

  .infoText {
    font-size: 12px;
  }
  .mapDiv {
    width: 100%;
    height: 100vw;
    margin-bottom: 4%;
    border: none;
  }
}

@media (max-width: 480px) {
  .card {
    padding: 5vw 6vw;
  }

  .logo {
    width: 25px;
    margin-bottom: 6vw;
  }

  .squareImg {
    width: 100%;
    /* max-width: 240px; */
    border-radius: 15.079px;
    background: #cfcfcf;
    height: auto;
  }

  .infoRow {
    margin: 6vw 0;
    gap: 4vw;
  }

  .infoText {
    color: #2d2d2d;
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
  }
  .logoDiv {
    display: flex;
    flex-direction: column;
  }
  .mapDiv {
    width: 100%;
    height: 100vw;
    margin-bottom: 4%;
    border: none;
  }
}
