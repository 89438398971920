.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2vw;
  margin: 5%;
}

.leftCol {
  flex: 1;
}

.rightCol {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.heading {
  font-size: 3vw;
  margin-bottom: 1vw;
  color: #333f92;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: 5vw;
  margin: 0;
}

.description {
  font-size: 1.3vw;
  color: #5d6266;
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  margin-top: 2vw;
  text-align: justify;
}

.image {
  width: 50%;
  height: auto;
  flex-shrink: 0;
  width: 29.6vw; /* or percentage based on parent */
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    text-align: center;
    margin: 2vw 5vw;
  }

  .leftCol {
    margin-bottom: 5vw;
  }

  .heading {
    font-size: 6vw;
    color: #333f92;
    text-align: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    line-height: 100px; /* 500% */
    margin: 0;
  }

  .description {
    font-size: 3.5vw;
    color: #5d6266;
    text-align: justify;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .rightCol {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .image {
    width: 100%; /* Occupy full width of the parent */
    max-width: 100%; /* Ensure it doesn't overflow */
  }
}
