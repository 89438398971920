.card {
  position: relative; /* Ensure the ::before pseudo-element is positioned correctly */
  background-color: #f9f9f9;
  padding: 4%;
  text-align: left;
  border-radius: 12px;
  background: #fff;
  transition: border 0.4s ease-in-out, transform 0.4s ease-in-out; /* Smooth border and transform transition */
  overflow: hidden; /* Prevent the shine effect from overlapping other cards */
}

.container {
  padding: 5% 6%;
  text-align: center;
  margin-top: 6vh;
  background: #f5f8ff;
}

.title {
  margin-bottom: 2%;
  color: #333f92;
  text-align: center;
  font-family: "DM Sans", sans-serif;
  font-size: 3vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.description {
  color: #5d6266;
  text-align: center;
  font-family: "DM Sans", sans-serif;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  margin: 1% 0 4% 0;
  padding: 0 10%;
}

.cardsContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Ensures 3 cards per row */
  gap: 2%;
  margin-top: 6vh;
  row-gap: 8%;
}

.card {
  height: 13vw;
  position: relative; /* Ensure the ::before pseudo-element is positioned correctly */
  background-color: #f9f9f9;
  padding: 7%;
  text-align: left;
  border-radius: 12px;
  background: #fff;
  transition: border 0.4s ease-in-out, transform 0.4s ease-in-out; /* Smooth border and transform transition */
  overflow: hidden; /* Prevent the shine effect from overlapping other cards */
}

.icon {
  margin-bottom: 1%;
}

.iconImage {
  width: 40px; /* Adjust the size of the icon image */
  height: auto;
}

.cardTitle {
  color: #14181f;
  font-family: Roboto, sans-serif;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 600;
  line-height: 1.4;
  margin: 0;
}

.cardDescription {
  color: #6f7c8e;
  font-family: Inter, sans-serif;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  margin: 4% 0px;
}

/* Responsive styling */
@media (max-width: 1024px) {
  .cardsContainer {
    grid-template-columns: repeat(
      2,
      1fr
    ); /* 2 cards per row on medium screens */
  }
}

@media (max-width: 768px) {
  .content {
    margin-top: 3rem; /* Adjust margin for tablets and smaller devices */
  }
  .cardsContainer {
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 6%;
  }

  .title {
    font-size: 6vw;
    margin: 5% 0px;
  }

  .description {
    font-size: 2.5vw;
    padding: 0;
  }

  .cardTitle {
    font-size: 4vw;
    text-align: center;
    margin: 4% 0;
  }

  .cardDescription {
    font-size: 3.3vw;
    text-align: center;
    display: none;
  }
  .icon {
    text-align: center;
  }
  .card {
    /* height: auto; */
    padding: 7%;
    margin-bottom: 4%;
  }
}

@media (max-width: 480px) {
  .content {
    margin-top: 3em; /* Adjust margin for very small devices like phones */
  }
  .cardsContainer {
    grid-template-columns: repeat(2, 1fr); /* 1 card per row on small screens */
    margin-bottom: 6%;
  }
  .card {
    /* height: max-content; */
    height: 15vw;
    padding: 7%;
    margin-bottom: 4%;
  }

  .cardDescription {
    font-size: 3.3vw;
    text-align: center;
    display: none;
  }
}
