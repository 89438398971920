.exploreProduct {
  padding: 0 5% 5% 5%;
  text-align: left;
  background: #f5f8ff;
}

.heading {
  font-size: 3vw;
  margin-bottom: 2vw;
  color: #333f92;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: 100px; /* 208.333% */
  margin: 0;
}

.subheading {
  font-size: 1.5vw;
  margin-bottom: 4vw;
  max-width: 66%;
  color: #5d6266;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 0;
}

/* Default cards container layout */
.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 2vw;
  justify-content: space-between;
}

/* Default card layout */
.card {
  width: calc(25% - 2vw);
  padding: 1vw;
  text-align: center;
  border-radius: 16px;
  border: 1px solid #e3e3e3;
  background: #fff;
}

.image {
  width: 100%;
  height: auto;
  border-radius: 4px;
}

.title {
  font-size: 1.5vw;
  color: #0e181e;
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  text-align: left;
}

.description {
  font-size: 1.3vw;
  color: #5d6266;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  text-align: left;
}

.readMore {
  font-size: 1.6vw;
  text-decoration: none;
  color: #333f92;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  float: left;
}

.readMore:hover {
  text-decoration: underline;
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .card {
    width: calc(33.33% - 4vw);
  }
}

@media (max-width: 900px) {
  .card {
    width: calc(90%);
  }
}
@media (max-width: 780px) {
  .heading {
    font-size: 5vw;
    text-align: center;
  }
  .subheading {
    font-size: 2.5vw;
    text-align: center;
    max-width: 100%;
  }
  .card {
    width: calc(90%);
    padding: 2vw;
    height: 51vw;
  }
  .image {
    width: 100%;
    height: auto;
    /* margin: 7% auto; */
    border-radius: 4px;
  }
  .title {
    font-size: 2.5vw;
  }
  .description {
    font-size: 2vw;
    margin: 3% 0;
  }
}
@media (max-width: 600px) {
  .exploreProduct {
    padding: 10% 5%;
    text-align: left;
    background: #f5f8ff;
  }

  /* Slider container styles */
  .sliderContainer {
    width: 100%;
    overflow: hidden;
  }

  .slick-slide {
    display: flex;
    justify-content: center;
  }

  .slick-track {
    display: flex;
    align-items: center;
  }

  .slick-slide > div {
    display: flex;
    justify-content: center;
  }

  .card {
    width: 94%; /* Adjust width to fit within the slider */
    max-width: 300px; /* Set a maximum width to prevent overflow */
    padding: 2vw 2vw 6vw 2vw;
    box-sizing: border-box; /* Include padding in width calculation */
    margin: 0 auto; /* Center the card */
  }

  .heading {
    font-size: 6vw;
    line-height: 1.2; /* Adjust line-height */
    text-align: center;
    overflow-wrap: break-word; /* Prevent text overflow */
  }

  .subheading {
    font-size: 3.2vw;
    text-align: center;
    max-width: 100%;
    overflow-wrap: break-word; /* Prevent text overflow */
    margin: 6vw 0;
  }

  .title {
    font-size: 5vw;
    overflow-wrap: break-word; /* Prevent text overflow */
  }

  .description {
    font-size: 3.5vw;
    overflow-wrap: break-word; /* Prevent text overflow */
  }

  .readMore {
    font-size: 3.5vw;
    overflow-wrap: break-word; /* Prevent text overflow */
  }

  .image {
    width: 100%; /* Ensure image fits within the card */
    height: auto;
    border-radius: 10px;
    display: block; /* Prevent image overflow */
  }
}
@media (max-width: 480px) {
  .card {
    /* height: 75vw; */
    height: max-content;
  }
}
