.coreFocusContainer {
  display: flex;
  flex-direction: column;
  padding: 5% 5%;
  background: rgba(245, 248, 255, 0.4);
  gap: 2rem;
}

.leftCol,
.rightCol {
  flex: 1;
  margin: 1rem 0;
}

.leftCol h2 {
  font-size: 4vw;
  margin: 0;
  color: #333f92;
  font-family: Roboto;
  font-weight: 700;
  margin-top: 0;
}

.leftCol p {
  font-size: 1.3vw;
  line-height: 1.6;
  color: #5d6266;
  font-family: "DM Sans";
  font-weight: 400;
  text-align: justify;
  margin: 2vw 0;
}

.table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 0.5rem;
  /* border: 1px solid black; */
}

.table thead th {
  text-align: left;
  padding: 1rem;
  vertical-align: top;
  color: #333f92;
  font-family: Poppins;
  font-size: 1vw;
  font-weight: 700;
  height: 5vh;
  border-right: 1px solid #ddd;
  text-align: center;
}

.table tbody td {
  padding: 1rem;
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
  color: #222;
  font-family: Poppins;
  font-size: 1vw;
  font-weight: 500;
  text-align: center;
}
.table tbody td:last-child,
.table thead th:last-child {
  border-right: none;
}

/* Responsive Design */
@media (min-width: 768px) {
  .coreFocusContainer {
    flex-direction: column;
  }

  .leftCol,
  .rightCol {
    margin: 0;
  }

  .leftCol {
    max-width: 100%;
  }

  .rightCol {
    max-width: 100%;
  }
  .leftCol h2 {
    font-size: 4vw;
    margin: 0;
    color: #333f92;
    font-family: Roboto;
    font-weight: 700;
    margin-top: 0;
  }
}

@media (max-width: 768px) {
  .coreFocusContainer {
    gap: 0px;
  }
  .leftCol h2 {
    color: #333f92;
    text-align: center;
    font-family: Roboto;
    font-size: 7vw;
    font-style: normal;
    font-weight: 700;
    line-height: 13vw; /* 416.667% */
  }

  .leftCol p {
    color: #5d6266;
    text-align: center;
    font-family: "DM Sans";
    font-size: 3vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .table thead th {
    color: #333f92;
    font-family: Poppins;
    font-size: 3vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .table tbody td {
    padding: 1rem;
    border-top: 1px solid #ddd;
    color: #222;
    font-family: Poppins;
    font-size: 2.5vw;
    font-weight: 500;
  }
}
@media (max-width: 480px) {
  .leftCol h2 {
    font-size: 6vw;
    /* text-align: justify; */
  }
  .leftCol p {
    text-align: justify;
  }
}
