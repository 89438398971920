/* Base Styles */
.blogContainer {
  padding-bottom: 5vw; /* Using viewport width for padding */
  background: #f5f8ff;
  margin-top: 5vw; /* Using viewport width for margin */
  display: flex;
  justify-content: center;
}

.blogWrapper {
  background: #f5f8ff;
  margin: 0 5vw; /* Using viewport width for margins */
  max-width: 90%; /* Use percentage to control maximum width */
  box-sizing: border-box; /* Ensure padding does not affect width */
}

.headerRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4vw; /* Using viewport width for margin */
  gap: 2vw; /* Using viewport width for gap */
}

.headerCol {
  margin-top: 6%; /* Using viewport width for margin */
  width: 60%; /* Use percentage for width */
}

.headerText {
  margin: 0;
  color: #333f92;
  font-family: Roboto, sans-serif;
  font-size: 3.5vw; /* Using viewport width for font size */
  font-weight: 700;
  line-height: 6vw; /* Using viewport width for line-height */
}

.descCol {
  width: 35%; /* Use percentage for width */
  text-align: right;
  margin-top: 10%; /* Using viewport width for margin */
}

.paraText {
  color: #5d6266;
  font-family: "DM Sans", sans-serif;
  font-size: 1.5vw; /* Using viewport width for font size */
  line-height: 1.5; /* Relative line-height */
  max-width: 100%;
  margin: 0;
}
.readMoreButton {
  background-color: #fff;
  color: #010205;
  padding: 0.5vw;
  border-radius: 1.5vw;
  border: 0.1vw solid #010205;
  cursor: pointer;
  display: flex;
  width: 5vw;
  align-items: center;
  gap: 1vw;
  justify-content: center;
}
.seeMoreButton {
  margin-top: 2vw; /* Using viewport width for margin */
  width: 13vw; /* Using viewport width for width */
  padding: 1vw 1.5vw; /* Using viewport width for padding */
  justify-content: center;
  cursor: pointer;
  border-radius: 50px;
  border: 0.1vw solid #fff; /* Using viewport width for border */
  background: #f5f8ff;
  border: 1px solid #333f92;
  color: #333f92;
  font-family: Poppins, sans-serif;
  font-size: 1.5vw; /* Using viewport width for font size */
  font-weight: 500;
  line-height: 1.4; /* Relative line-height */
  letter-spacing: -0.02em; /* Relative letter-spacing */
}

.cardsRow {
  display: flex;
  flex-wrap: wrap;
  gap: 2vw; /* Using viewport width for gap */
}

.card {
  flex: 1;
  min-width: 20vw; /* Using viewport width for min-width */
  max-width: 30vw; /* Using viewport width for max-width */
  border: 1px solid #ddd;
  border-radius: 1vw; /* Using viewport width for border-radius */
  overflow: hidden;
  box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.1);
  background-color: #fff;
  display: flex;
  padding: 2vw; /* Using viewport width for padding */
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
}

.cardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5vw; /* Using viewport width for margin */
}

.circleDot {
  width: 1vw; /* Using viewport width for width */
  height: 1vw; /* Using viewport width for height */
  background-color: #007bff;
  border-radius: 50%;
  margin-right: 0.5vw; /* Using viewport width for margin */
}

.blogHeading {
  margin: 0;
  color: #010205;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 1.5vw; /* Using viewport width for font size */
  font-weight: 600;
  line-height: 1.5; /* Relative line-height */
  letter-spacing: -0.02em; /* Relative letter-spacing */
}

.blogImage {
  width: 100%;
  height: auto;
  margin: 1.5vw 0; /* Using viewport width for margin */
  border-radius: 1vw; /* Using viewport width for border-radius */
}

.shortText {
  width: 64%; /* Use percentage for width */
  overflow: hidden;
  color: #878c91;
  text-overflow: ellipsis;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 1.2vw; /* Using viewport width for font size */
  font-weight: 500;
  line-height: 1.6; /* Relative line-height */
  margin: 0;
}

.cardFooter {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.readMore {
  flex: 1;
}

.time {
  overflow: hidden;
  color: #878c91;
  text-overflow: ellipsis;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 1.2vw; /* Using viewport width for font size */
  font-weight: 500;
  line-height: 1.6; /* Relative line-height */
}

/* Responsive Layout Adjustments */
@media (max-width: 1200px) {
  .blogWrapper {
    margin: 0 3vw; /* Adjusted margins for medium screens */
  }

  .headerCol {
    width: 100%; /* Full width on smaller screens */
    margin-top: 2vw; /* Adjust margin for smaller screens */
  }

  .descCol {
    width: 100%; /* Full width on smaller screens */
    text-align: center; /* Center align text on smaller screens */
    margin-top: 2vw; /* Adjust margin for smaller screens */
  }

  .cardsRow {
    gap: 2vw; /* Ensure cards have space on medium screens */
  }

  .card {
    width: 100%; /* Full width on smaller screens */
    padding: 1.5vw; /* Adjust padding for smaller screens */
  }
}

@media (max-width: 768px) {
  .blogWrapper {
    margin: 0 2vw; /* Adjust margins for smaller screens */
  }

  .blogContainer {
    padding-bottom: 2vw; /* Adjust padding for smaller screens */
    background: #f5f8ff;
    margin-top: 2vw; /* Adjust margin for smaller screens */
  }

  .headerRow {
    gap: 5vw;
    flex-direction: column; /* Stack header content vertically */
    margin-top: 2vw; /* Adjust margin for smaller screens */
  }

  .headerCol,
  .descCol {
    width: 100%; /* Full width for both columns */
    margin-top: 0; /* Remove top margin */
    text-align: center; /* Center align text */
  }

  .cardsRow {
    flex-direction: column; /* Stack cards vertically */
    align-items: center;
  }

  .card {
    min-width: 100%;
    max-width: 100%;
    width: 100%; /* Full width for each card */
    padding: 3.5vw; /* Adjust padding for smaller screens */
    margin-bottom: 4vw; /* Add margin between cards */
  }

  .blogHeading {
    font-size: 1.5vw; /* Adjust font size for headings */
    margin-bottom: 2vw;

    font-family: "Plus Jakarta Sans";
    font-size: 4vw;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 26.999px */
    letter-spacing: -0.54px;
    text-align: left;
  }

  .headerText {
    font-size: 4vw; /* Adjust font size for header text */
    text-align: center;
  }

  .paraText {
    font-size: 2.5vw; /* Adjust font size for paragraph text */
    text-align: center;
  }

  .shortText {
    font-size: 4vw;
    overflow: hidden;
    color: #878c91;
    /* text-overflow: ellipsis; */
    /* white-space: nowrap; */
    font-family: "Plus Jakarta Sans";
    font-size: 4vw;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    width: 70%;
    text-align: left;
  }

  .readMoreButton {
    width: 10vw;
    padding: 1vw;
    border-radius: 39px;
  }

  .arrowImg {
    width: 4vw; /* Adjust arrow icon size */
    height: 4vw; /* Adjust arrow icon size */
  }

  .blogImage {
    width: 100%;
    height: auto;
    margin: 5% 0;
    border-radius: 5.821px;
  }
  .time {
    font-size: 4vw;
  }
  .seeMoreButton {
    font-size: 2vw;
    margin-top: 2%;
  }
}

@media (max-width: 576px) {
  .blogWrapper {
    margin: 0 2vw; /* Adjust margins for very small screens */
  }

  .headerText {
    font-size: 6vw; /* Further reduce font size for headings */
    text-align: center;
    line-height: 14vw;
  }

  .paraText {
    font-size: 3vw;
    line-height: 4vw; /* Further reduce font size for paragraph text */
    text-align: center;
  }

  .seeMoreButton {
    display: none;
    width: 5vw; /* Further reduce button width */
    font-size: 1vw; /* Further reduce font size for button */
  }

  .card {
    width: 100%; /* Full width for very small screens */
    max-width: 100%;
    border-radius: 13px;
  }
}
