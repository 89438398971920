.historyContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5vw 0;
  gap: 2vw;
  max-width: 90%;
  margin: 0 auto;
}

.leftCol {
  flex: 1;
  max-width: 50%;
}

.heading {
  font-size: 3.5vw;
  margin-bottom: 2vw;
  color: #333f92;
  font-family: Roboto, sans-serif;
}

.description {
  font-size: 1.3vw;
  line-height: 1.6;
  color: #5d6266;
  font-family: "DM Sans", sans-serif;
}

.rightCol {
  flex: 1;
  max-width: 50%;
  display: flex;
  justify-content: end;
}

.image {
  max-width: 100%;
  height: auto;
  width: 38vw; /* Adjust as needed to maintain aspect ratio */
  border-radius: 1vw;
}

@media (max-width: 768px) {
  .historyContainer {
    flex-direction: column;
    padding: 4vw 0vw;
  }

  .leftCol,
  .rightCol {
    max-width: 100%;
  }

  .heading {
    font-size: 6vw;
    text-align: center;
  }

  .description {
    font-size: 3vw;
    text-align: center;
  }

  .rightCol {
    margin-top: 3vw;
  }
  .image {
    width: 100vw;
    border-radius: 12px;
  }
}
@media (max-width: 480px) {
  .heading {
    font-size: 5vw;
    margin: 6% 0;
  }
  .description {
    line-height: 4vw;
    text-align: justify;
  }
}
