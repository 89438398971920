.sliderContainer {
  display: flex;
  overflow: hidden;
  position: relative;
  margin: 5.4% 5%;
  width: 90vw;
  height: 35vw; /* Adjust as necessary */
}

.imageContainer {
  position: absolute;
  width: 100%;
  /* height: 100%; */
  height: auto;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.active {
  opacity: 1;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}

.overlayText {
  position: absolute;
  bottom: 2%;
  left: 1%;
  width: 90%;
  max-width: 41.7%;
  font-size: 3.5vw;
  color: white;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
  font-family: "DM Sans";
  font-weight: 500;
  line-height: 1.2;
  padding: 1% 2%;
  box-sizing: border-box;
  word-wrap: break-word;
}

.indicators {
  position: absolute;
  bottom: 20px; /* Adjust this value to position the indicators lower */
  left: 50%;
  transform: translateX(-50%);
  display: flex; /* Ensure they are in a row */
  justify-content: center; /* Center the indicators */
}

.indicator {
  cursor: pointer;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%;
  background: lightgray;
}

.activeIndicator {
  background: white;
}

/* Media Queries */
@media (max-width: 768px) {
  .sliderContainer {
    width: 100vw;
    height: 60vw;
    margin: 0 auto;
    /* margin: 5% 0% 2% 0%; */
  }

  .overlayText {
    font-size: 4vw;
    bottom: 3%;
    left: 3%;
    /* padding: 1% 4%; */
  }
}

@media (max-width: 480px) {
  .imageContainer {
    width: 100vw;
    height: auto;
    height: 100%;

    justify-content: center;
  }
  .sliderContainer {
    width: 90vw;
    /* height: 70vw; */
  }

  .overlayText {
    font-size: 3.9vw;
    bottom: 5%;
    left: 5%;
    line-height: 1.5;
  }
}
