.faqContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 2%; /* Adjust gap to percentage */
  margin: 0 auto;
  max-width: 65%; /* Remove fixed width */
  padding: 2% 5%; /* Adjust padding to percentage */
}
.wrapper {
  margin-top: 5%;
}
.column {
  flex: 1;
  min-width: 300px; /* Minimum width to prevent too small columns */
  margin: 1% 0; /* Adjust margin to percentage */
}

.title {
  color: #333f92;
  text-align: center;
  font-family: "Plus Jakarta Sans";
  font-size: 4.2vw; /* Responsive font size based on viewport width */
  font-style: normal;
  font-weight: 700;
  line-height: 1.2; /* Relative line height */
  margin: 0;
}

.faqItem {
  /* Optional styling, uncomment if needed */
  /* background-color: #f9f9f9; */
  /* border: 1px solid #ddd; */
  margin-bottom: 1%; /* Adjust margin to percentage */
  /* border-radius: 5px; */
  padding: 2%; /* Adjust padding to percentage */
}

.faqHeader {
  display: flex;
  justify-content: space-between;
  align-items: start;
  cursor: pointer;
}

.faqIcon {
  margin-right: 2%; /* Adjust margin to percentage */
}

.faqQuestion {
  flex: 1;
  color: #0e181e;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Plus Jakarta Sans";
  font-size: 1.7vw;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 127.273% */
}

.dropdown {
  font-size: 1.5rem; /* Responsive font size */
}

.faqAnswer {
  margin-top: 3%;
  width: 100%;
  font-size: 1.4vw;

  color: var(--black-100, #000);
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border-radius: 10px;
  background: rgba(234, 95, 56, 0.07);
  padding: 2%;
}

@media (max-width: 768px) {
  .faqContainer {
    flex-direction: column;
    max-width: 100%;
  }
  .faqItem {
    padding: 2% 0;
  }
  .column {
    width: 100%;
    margin: 1% 0; /* Adjust margin to percentage */
  }
  .title {
    margin: 8% 0% 5% 0;
    font-size: 6vw;
  }

  .faqQuestion {
    flex: 1;
    color: #0e181e;
    font-feature-settings: "liga" off, "clig" off;
    font-size: 3.6vw;
    font-style: normal;
    line-height: 18.41px;

    font-family: "Plus Jakarta Sans";
    font-size: 2.7vw;
    font-style: normal;
    font-weight: 500;
    line-height: 18.41px;
  }
  .faqAnswer {
    margin-top: 2%; /* Adjust margin to percentage */
    padding-left: 0%; /* Adjust padding to percentage */
    color: #333;
    width: 100%;
    font-size: 2.4vw;
    font-weight: 500;
    padding: 2%;
  }
  .iconf {
    /* width: 30px; */
    display: none;
  }
}

@media (max-width: 480px) {
  .faqQuestion {
    color: #0e181e;
    font-feature-settings: "liga" off, "clig" off;
    font-family: "Plus Jakarta Sans";
    font-size: 14.465px;
    font-size: 4vw;
    font-style: normal;
    font-weight: 500;
    line-height: 18.41px; /* 127.273% */
  }
  .faqAnswer {
    color: var(--black-100, #000);
    font-feature-settings: "liga" off, "clig" off;
    font-family: "Plus Jakarta Sans";
    font-size: 3.4vw;
    font-style: normal;
    font-weight: 400;
    line-height: 18.41px; /* 153.414% */
  }
}
