.contactFormContainer {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  gap: 6vw; /* Use vw for gap */
  margin: 10vw 5vw; /* Use vw for margin */
}
.a.ff-powered-img img {
  display: none !important;
}
.mapDiv {
  width: 30vw;
  height: 20vw;
  margin-bottom: 4%;
}
.leftCol,
.rightCol {
  /* flex: 1;
  min-width: 30vw;  */
}

.rightCol {
  /* display: flex;
  align-items: start;
  justify-content: flex-start; */
}

.heading {
  color: var(--Gray-900, #101828);
  font-family: "DM Sans";
  font-size: 2.7vw; /* Use vw for font-size */
  font-style: normal;
  font-weight: 700;
  line-height: 3vw; /* Use vw for line-height */
  letter-spacing: -0.72px;
  margin: 0;
}

.subheading {
  color: var(--Gray-600, #475467);
  font-family: "DM Sans";
  font-size: 1.6vw; /* Use vw for font-size */
  font-style: normal;
  font-weight: 400;
  line-height: 3vw; /* Use vw for line-height */
  margin: 0;
  margin-bottom: 8%;
  margin-top: 3%;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 2.5vw; /* Use vw for gap */
}

.row {
  display: flex;
  flex-wrap: wrap;
  gap: 2vw; /* Use vw for gap */
}

.field {
  /* min-width: 40vw;  */
}

.fieldFullWidth {
  flex: 1 1 100%;
}

label {
  display: block;
  margin-bottom: 0.3vw; /* Use vw for margin-bottom */
  color: var(--Gray-700, #344054);
  font-family: "DM Sans";
  font-size: 1.4vw; /* Use vw for font-size */
  font-style: normal;
  font-weight: 500;
  line-height: 2vw; /* Use vw for line-height */
}

.field input,
.row .fieldFullWidth input,
textarea {
  /* width: 36vw; */
  padding: 1vw; /* Use vw for padding */
  border-radius: 1vw; /* Use vw for border-radius */
  font-size: 1.2vw; /* Use vw for font-size */
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--White, #fff);
  box-shadow: 0px 0.5vw 1vw rgba(16, 24, 40, 0.05); /* Use vw for box-shadow */
}
.textareaWidth {
  height: 90px;
}

.row .fieldFullWidth input,
textarea {
  width: 36vw; /* Use vw for width */
}

.buttonOne,
.buttonTwo {
  border: none;
  cursor: pointer;
  display: flex;
  padding: 1vw 1vw; /* Use vw for padding */
  justify-content: center;
  align-items: center;
  gap: 1vw; /* Use vw for gap */
  border-radius: 75px; /* Use vw for border-radius */
  font-family: "DM Sans";
  font-size: 1.2vw; /* Use vw for font-size */
  font-style: normal;
  font-weight: 700;
  line-height: 1.8vw; /* Use vw for line-height */
}

.buttonOne {
  background: #333f92;
  color: #fff;
}

.buttonTwo {
  border: 1px solid #333f92;
  color: #333f92;
  background-color: transparent;
}

@media (max-width: 768px) {
  .contactFormContainer {
    flex-direction: column;
  }

  .row {
    flex-direction: row;
    justify-content: space-between;
  }

  .buttonOne,
  .buttonTwo {
    /* width: 36%; */
    text-align: center;
    font-size: 3vw;
    padding: 4%;
  }

  .leftCol,
  .rightCol {
    flex: 1;
    min-width: 30vw;
  }

  .heading {
    text-align: left;
    font-size: 6vw;
    line-height: 44px; /* 220% */
  }

  .subheading {
    font-size: 4vw;
    text-align: left;
    color: var(--Gray-600, #475467);
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 187.5% */
  }

  label {
    color: var(--Gray-700, #344054);
    font-family: "DM Sans";
    font-size: 4vw;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    margin-bottom: 8%;
  }

  .field input,
  .row .fieldFullWidth input,
  textarea {
    height: 8vw;
    width: 40vw;
    border-radius: 2vw;
    font-size: 3.2vw;
    font-family: "DM Sans";
  }

  .form {
    gap: 24px;
  }

  .row .fieldFullWidth input {
    width: 90vw; /* Use vw for width */
  }

  textarea {
    width: 89vw; /* Use vw for width */
    height: 100%;
    margin-bottom: 5%;
  }

  .btnDiv > .row {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
}
