/* Base Styles (Mobile First Approach) */
.blogDetailContainer {
  max-width: 90%; /* Slightly wider on mobile for better readability */
  margin: 5vh auto;
  padding: 1vw 0vw; /* More padding on smaller screens for better spacing */
}

.blogDetailWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 5%;
  /* gap: 5vw; */
}

.headeWrapper {
  /* width: 80%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: centers;
}
.metaInfo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 4vw;
  color: #999;
  gap: 8%;
  margin-bottom: 3%;
}

.category {
  text-transform: uppercase;
  font-weight: bold;
  color: #333f92;
  font-family: "DM Sans";
  font-size: 2vw;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
}

.date {
  color: #363d41;
  font-family: "DM Sans";
  font-size: 2vw;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
}

.title {
  color: #333;
  font-family: "DM Sans";
  font-size: 3vw;
  color: #0f153d;
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.blogImage {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  width: 100%;
  height: auto;
  margin: 3% 0%;
  border-radius: 3vw; /* Larger radius on mobile for a softer look */
}

.section {
  /* margin-top: 5vh; */
}

.subTitle {
  font-size: 3vw;
  text-align: center;
  margin: 3% 0;
  margin-bottom: 3vh;
}

.paragraph {
  color: #666;
  font-family: "DM Sans";
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: center;
}

/* Tablet Styles */

/* Desktop Styles */

@media (max-width: 768px) {
  .metaInfo {
    display: flex;
    font-display: row;
  }
  .paragraph {
    text-align: justify;
  }
  .title {
    margin: 4% 0;
  }
  .subTitle {
    margin: 4% 0;
  }
}

@media (max-width: 480px) {
  .title {
    margin: 4% 0;
  }
  .subTitle {
    margin: 4% 0;
  }
}
