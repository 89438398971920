.heroContainer {
  text-align: center;
  margin: 8% 10%; /* Adjusted margin with percentage */
}

.heroTitle {
  color: #333f92;
  font-family: Roboto;
  font-size: 5vw; /* Responsive font size */
  font-style: normal;
  font-weight: 700;
  line-height: 8vw; /* Adjust line height accordingly */
  margin: 0;
}

.heroSubtitle {
  font-size: 1.4vw; /* Responsive font size */
  margin-bottom: 2vw; /* Margin with viewport units */
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  color: #5d6266;
  text-align: center;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.heroImage {
  width: 100%;
  /* max-width: 80%; / */
  height: auto;
  object-fit: cover;
  border-radius: 1vw; /* Border-radius with viewport units */
  margin: 0 auto;
}

/* Media Queries */
@media (max-width: 768px) {
  .wrapper {
    margin-top: 19%;
  }
  .heroTitle {
    font-size: 4vw; /* Adjust font size */
    line-height: 5vw; /* Adjust line height */
  }

  .heroSubtitle {
    width: 100%;
    max-width: 100%;
    font-size: 2.4vw; /* Adjust font size */
    margin: 3% 0 3% 0; /* Adjust margin */
  }
  .heroContainer {
    margin: 8% 7%;
  }
}

@media (max-width: 480px) {
  .heroContainer {
    margin: 8% 7%;
  }
  .heroTitle {
    font-size: 7vw; /* Adjust font size */
    line-height: 6vw; /* Adjust line height */
  }

  .heroSubtitle {
    font-size: 3vw; /* Adjust font size */
    line-height: 4vw;

    max-width: 100%;
    color: #5d6266;
    text-align: justify;
    font-family: "DM Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .heroImage {
    margin-top: 5%;
    height: 26vw;
    border-radius: 8px;
  }
}
