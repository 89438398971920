.card {
  padding: 1.2vw;
  width: calc(30%); /* Use relative units for width and gaps */
  text-align: center;
  box-sizing: border-box;
  border-radius: 1vw;
  background: #fff;
  position: relative; /* Required for the pseudo-element */
  overflow: hidden; /* Ensure the shine effect doesn't spill out */
}

.wrapper {
  background: #f5f8ff;
}

.container {
  text-align: center;
  padding: 5vw 5vw; /* Use viewport width for padding */
}

.title {
  color: #333f92;
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 2vw; /* Relative font size */
  font-weight: 500;
  line-height: 1.5; /* Adjust line height */
  margin: 0;
  margin-bottom: 2vw;
}

.description {
  color: #0e181e;
  text-align: center;
  font-family: "DM Sans", sans-serif;
  font-size: 3vw; /* Relative font size */
  font-weight: 700;
  line-height: 1.2;
  margin: 0;
  padding: 0 10vw; /* Use viewport width for padding */
}

.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2vw; /* Use relative units for gaps */
  margin-top: 5vw;
}

.icon {
  width: 2.5vw; /* Relative width */
  height: 2.5vw; /* Relative height */
  margin-bottom: 2vw;
}

.cardTitle {
  font-size: 1.3vw; /* Relative font size */
  margin-bottom: 1vw;
  color: #14181f;
  font-family: Roboto, sans-serif;
  font-weight: 600;
  line-height: 1.5;
}

.cardDescription {
  color: #5d6266;
  font-family: "DM Sans", sans-serif;
  font-size: 1.1vw; /* Relative font size */
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 1vw;
}

@media (max-width: 768px) {
  .card {
    width: calc(50% - 2vw);
    padding: 5vw 2vw;
  }

  .title {
    color: #333f92;
    text-align: center;
    font-family: Roboto;
    font-size: 5vw;
    font-style: normal;
    font-weight: 500;
    line-height: 12vw; /* 833.333% */
    margin-bottom: 0;
  }
  .description {
    color: #0e181e;
    text-align: center;
    font-family: "DM Sans";
    font-size: 4vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
    padding: 0 0vw; /* Use viewport width for padding */
  }
  .cardsContainer {
    margin-top: 8vw;
  }
  .icon {
    width: 5vw;
    height: 5vw;
  }
  .cardTitle {
    color: var(--Text-color-base, #14181f);
    font-family: Roboto;
    font-size: 4vw;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 19.6px */
  }
  .cardDescription {
    color: #5d6266;
    text-align: center;
    font-family: "DM Sans";
    font-size: 3.5vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .container {
    padding: 8vw 5vw;
  }
}

@media (max-width: 480px) {
  .card {
    width: calc(100% - 2vw); /* 1 card per row on very small screens */
  }
  .cardTitle {
    margin: 3% 0;
  }
  .icon {
    width: 9vw;
    height: 9vw;
  }
  .cardsContainer {
    gap: 5vw;
  }
}
