.customerSection {
  text-align: center;
  margin-top: 5%; /* Changed from px to percentage */
  overflow: hidden; /* Hide overflow to only show the scrolling icons */
  position: relative;
}

.title {
  color: #333f92;
  text-align: center;
  font-family: "DM Sans";
  font-size: 3vw; /* Changed from px to vw */
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0;
  margin-top: 0;
}

.marqueeContainer {
  overflow: hidden;
  width: 100%;
  position: relative;
}

.marquee {
  display: flex;
  gap: 2.5%; /* Changed from rem to percentage */
  animation: marquee 5s linear infinite;
  margin-top: 4%;
  /* Add transition for smoother effect */
  transition: animation 0.5s;
}

.marquee:hover {
  animation-play-state: paused; /* Pause animation on hover */
}

.icon {
  width: 15vw; /* Changed from px to vw */
  height: 6vw; /* Changed from px to vw */
  object-fit: contain;
  border-radius: 10px; /* Rounded corners for the border */

  width: 100%;
  height: max-content;
}

/* Marquee Animation */
@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

/* Fade Effect on Left and Right Sides of the Main Container */
.customerSection::before,
.customerSection::after {
  content: "";
  position: absolute;
  top: 0;
  width: 20%; /* Adjust this percentage to control the fade width */
  height: 100%;
  z-index: 1;
  pointer-events: none;
}

.customerSection::before {
  left: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), #fff 100%);
}

.customerSection::after {
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff 100%);
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .title {
    font-size: 3vw;
  }

  .icon {
    width: 100%;
    height: max-content;
  }
}

@media (max-width: 768px) {
  .title {
    font-size: 4vw;
  }

  .icon {
    width: 35%;
    height: 3.5vw;

    width: 100%;
    height: max-content;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 6vw;
    margin: 5% 0px;
  }

  .icon {
    width: 35%;
    height: auto;
  }
}

@media (max-width: 320px) {
  .title {
    font-size: 6vw;
  }

  .icon {
    width: 35%;
    height: 2.5vw;
  }
}
