.container {
  display: flex;
  flex-wrap: wrap;
  gap: 2vw; /* Adjust spacing between columns */
  margin: 4vw 5%;
}

.leftCol,
.rightCol {
  flex: 1;
  min-width: 40vw; /* Minimum width for responsiveness */
}

.leftCol {
  border-right: 1px solid #ccc; /* Optional: Add a border to separate columns */
  padding-right: 2vw;
}

.rightCol {
  padding-left: 2vw;
}

h2 {
  font-size: 2.5vw; /* Responsive font size */
  margin-bottom: 1vw;
  color: #2d2d2d;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: 4.5vw; /* 225% */
}

.additionalHead {
  color: #333f92;
  font-family: Roboto;
  font-size: 2.5vw;
  font-style: normal;
  font-weight: 700;
  line-height: 4.5vw; /* 225% */
}

.addSubHeader {
  color: #2d2d2d;
  font-family: Roboto;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 500;
  line-height: 3vw; /* 200% */
  margin: 0;
}
.addSubHeader {
  font-size: 2vw; /* Responsive font size */
  margin-top: 2vw;
}

.leftCol p {
  font-size: 1.4vw;
  margin-bottom: 1vw;
  color: #5d6266;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

ul {
  padding-left: 2vw;
}
.infoSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.infoSection ul li {
  font-size: 1.3vw; /* Responsive font size */
  color: #909090;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: 2.5vw; /* 160% */
}

hr {
  margin: 2vw 0;
  background: #333f92;
}

/* Media Queries for Smaller Devices */

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    margin: 4vw 5%;
  }

  .infoSection {
    flex-direction: row;
    align-items: center;
    gap: 2vw;
    justify-content: space-between;
    /* margin: 10% 0; */
  }
  .leftCol,
  .rightCol {
    min-width: 100%; /* Full width columns on small screens */
    padding: 0;
  }

  .leftCol {
    border-right: none; /* Remove border on small screens */
    border-bottom: 1px solid #ccc; /* Optional: Add border below */
    padding-bottom: 2vw;
  }

  .addSubHeader,
  .additionalHead {
    font-size: 4vw;
    line-height: 7vw; /* Adjust line height for smaller screens */
  }

  .addSubHeader {
    font-size: 3vw;
    line-height: 4vw;
  }
  .leftCol p {
    font-size: 2.4vw;
  }
  h3 {
    font-size: 3vw;
    margin-top: 4vw;
  }

  /* p {
    font-size: 3vw;
    line-height: 5vw;
  } */

  .infoSection ul li {
    font-size: 3vw;
    line-height: 5vw;
  }
  .infoSection {
    /* margin-top: 5%;s */
    flex-direction: row;
    align-items: center;
  }
}

@media (max-width: 480px) {
  .leftCol h2 {
    margin: 2% 0;
    text-align: center;
    font-size: 5vw;
    line-height: 8vw;
  }
  .additionalHead {
    margin: 10% 0 0 0;
    text-align: center;
    font-size: 5vw;
    line-height: 8vw;
  }
  ul {
    text-align: center;
    list-style: none;
  }
  hr {
    margin: 6vw 0;
    background: #333f92;
  }
  .addSubHeader {
    font-size: 4vw;
    line-height: 6vw;

    text-align: center;
  }

  h3 {
    font-size: 4vw;
    margin-top: 5vw;
  }

  .leftCol p {
    font-size: 3vw;
    line-height: 4vw;
    text-align: center;
  }

  .infoSection {
    flex-direction: column;
    align-items: center;
    gap: 4vw;
    justify-content: space-around;
    margin: 5% 0;
    align-items: flex-start;
  }
  .infoSection ul li {
    font-size: 4vw;
    line-height: 6vw;
  }
  .firstDiv,
  .secondDiv {
    flex: 1;
    min-width: 100%; /* Minimum width for responsiveness */
  }
}
