.heroContainer {
  text-align: center;
  padding: 2% 5%; /* Updated padding to percentage units */
  margin: 3% auto; /* Updated margin to percentage units */
}

.heroTitle {
  color: #333f92;
  text-align: center;
  font-family: Roboto;
  font-size: 4vw; /* Updated font size to viewport width units */
  font-style: normal;
  font-weight: 700;
  line-height: 8vw; /* Updated line height to viewport width units */
  margin: 0;
}

.heroSubtitle {
  font-size: 1.5vw; /* Updated font size to viewport width units */
  margin-bottom: 4%; /* Updated margin to percentage units */
  max-width: 80%; /* Updated max-width to percentage units */
  margin-left: auto;
  margin-right: auto;
  color: #5d6266;
  text-align: center;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.heroImage {
  width: 100%;
  max-width: 100%; /* Use 100% for max-width to ensure responsiveness */
  height: auto;
  object-fit: cover;
  flex-shrink: 0;
  margin: 0 auto;
  border-radius: 8px; /* Updated border-radius to percentage units */
}

@media (max-width: 768px) {
  .heroTitle {
    font-size: 8vw; /* Adjusted font size for smaller screens */
    line-height: 10vw; /* Adjusted line height for smaller screens */
  }

  .heroSubtitle {
    max-width: 100%;
    font-size: 2vw; /* Adjusted font size for smaller screens */
    margin-bottom: 3%; /* Adjusted margin for smaller screens */
  }
  .heroImage {
    margin: 3% 0px;
  }
}

@media (max-width: 480px) {
  .heroContainer {
    margin: 17% 0 0 0;
  }
  .heroTitle {
    font-size: 7vw; /* Adjusted font size for extra-small screens */
    line-height: 10vw; /* Adjusted line height for extra-small screens */
  }

  .heroSubtitle {
    font-size: 3vw;
    line-height: 4vw;
    margin: 2% 0; /* Adjusted margin for extra-small screens */
  }
}
