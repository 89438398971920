.banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4% 8%;
  background: #0f153d;
  position: relative;
}

.imgOne {
  position: absolute;
  left: 0;
}

.imgTwo {
  position: absolute;
  bottom: -4px;
  right: 0;
}

.leftSection {
  display: flex;
  flex-direction: column;
}

.heading {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  color: #fff;
  font-family: Roboto;
  font-size: 66px;
  font-style: normal;
  font-weight: 700;
  line-height: 72px; /* 109.091% */
}

.subheading {
  font-size: 1.25rem;
  color: #fff;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 150% */
}

.rightSection {
  flex-shrink: 0;
}

.image {
  width: 200px;
  height: 200px;
  object-fit: cover;
}

/* Responsiveness */
@media (max-width: 768px) {
  .banner {
    flex-direction: column;
    align-items: center; /* Center align all content */
    text-align: center; /* Ensure text is centered */
    padding: 10%;
  }

  .imgOne,
  .imgTwo {
    display: none;
  }

  .image {
    width: 100%;
    height: auto;
    margin-top: 1.5rem;
  }

  .heading {
    font-size: 7vw;
  }

  .subheading {
    font-size: 3vw;
  }
}

@media (max-width: 480px) {
  .banner {
    padding: 15% 4%;
  }

  .heading {
    font-size: 9.5vw;
  }

  .subheading {
    font-size: 0.875rem;

    color: #fff;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 187.5% */
  }

  .image {
    width: 100%;
    height: auto;
    margin-top: 1.5rem;
  }
}
