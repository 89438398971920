.container {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  padding: 3% 0; /* Adjusted padding to use percentage */
}

.title {
  color: #0f153d;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: "DM Sans";
  font-size: 4vw; /* Adjusted font size to use viewport width */
  font-style: normal;
  font-weight: 700;
  line-height: 1.9; /* Relative line height */
  margin: 0;
}

.divider {
  width: 20%; /* Adjusted width to use percentage */
  margin: 1% auto; /* Adjusted margin to use percentage */
  border: 0;
  border-top: 2px solid #333f92;
}

.description {
  color: #666;
  font-family: "DM Sans";
  font-size: 1.5vw; /* Adjusted font size to use viewport width */
  font-style: normal;
  font-weight: 400;
  line-height: 1.5; /* Relative line height */
}

@media (max-width: 768px) {
  .description {
    font-size: 2.5vw;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 5vw;
  }
  .description {
    font-size: 3vw;
    line-height: 4vw;
    text-align: justify;
  }
}
