.card {
  padding: 3%;
  text-align: center;
  border-radius: 8px;
  border: 1px solid #ccc;
  background: #fff;
}

@media (max-width: 768px) {
  .content {
    margin-top: 3rem; /* Adjust margin for tablets and smaller devices */
  }
}

@media (max-width: 480px) {
  .content {
    margin-top: 3em; /* Adjust margin for very small devices like phones */
  }
}

.container {
  padding: 2% 4%;
  max-width: 100%;
  margin: 0 auto;
}

.secondDiv {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2vw; /* Add a gap for spacing between columns */
}

.header {
  margin-top: 5vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 3vw;
}

.title {
  color: #333f92;
  text-align: center;
  font-family: "DM Sans";
  font-size: 3vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 1vw;
  margin-top: 0px;
}

.description {
  color: #5d6266;
  font-family: "DM Sans";
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 2vw;
  max-width: 74%;
}

.button {
  align-self: flex-start;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1.6vw;
  border-radius: 76px;
  border: 1px solid #333f92;
  background-color: #fff;
  padding: 1vw 2vw;
  color: #333f92;
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "DM Sans";
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 700;
  line-height: 2vw;
}

.content {
  display: flex;
  justify-content: space-between;
  gap: 2vw;
  flex-wrap: wrap;
}

.leftColumn {
  flex: 1;
  display: grid;
  gap: 2vw; /* Space between cards */
  grid-template-columns: repeat(2, 1fr); /* 2 columns */
  grid-template-rows: repeat(2, auto); /* 2 rows */
}

.card {
  padding: 3%;
  text-align: center;
  border-radius: 8px;
  border: 1px solid #ccc;
  background: #fff;
}

.cardImage {
  width: 100%;
  height: auto;
  max-width: 100%;
  border-radius: 8px;
  background: #2d2d2d;
}

.cardTitle {
  color: #0e181e;
  font-family: "DM Sans";
  font-size: 1.4vw;
  font-style: normal;
  font-weight: 500;
  line-height: 2.5vw;
  margin: 0.5vw 0;
  text-align: left;
}

.cardDescription {
  color: #0e181e;
  font-family: Inter;
  font-size: 1.1vw;
  font-style: normal;
  font-weight: 400;
  margin: 0;
  text-align: left;
}

.rightColumn {
  flex: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.verticalImage {
  width: 100%;
  height: auto;
  max-height: 40rem; /* Set a maximum height to maintain aspect ratio */
  border-radius: 5px;
  flex-shrink: 0;
  object-fit: cover; /* Ensures the image covers the area proportionally */
}

@media (max-width: 768px) {
  .card {
    border: none;
    position: relative;
    overflow: hidden;
  }

  .cardImage {
    width: 30vw; /* Adjust this value as per your design */
    height: auto;
    flex-shrink: 0;
    border-radius: 10px;
    background: lightgray 50% / cover no-repeat;
  }

  .card::after {
    content: "";
    position: absolute;
    bottom: 12vw;
    left: 0;
    width: 100%;
    height: 20%; /* Reduce the gradient height for mobile */
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(208, 208, 208, 0.7) 0%,
      #e8e8e8 28.5%,
      rgba(245, 245, 245, 0.8) 63.5%,
      #fffdfd 82.5%,
      #fff 100%
    );
    z-index: 1;
  }

  .btnDiv {
    display: flex;
    justify-content: center;
    margin: 3% 0;
  }

  .button {
    font-size: 3.5vw;
    padding: 3% 4%;
    width: 36%;
    line-height: 18px;
  }

  .secondDiv {
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  .title {
    color: #333f92;
    text-align: center;
    font-family: "DM Sans";
    font-size: 6vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 1vw;
    margin-top: 0px;
  }

  .content {
    flex-direction: column;
  }

  .leftColumn {
    grid-template-columns: repeat(2, 1fr);
    gap: 5vw;
  }

  .rightColumn {
    margin-top: 5vw;
  }

  .header {
    align-items: center;
  }

  .description {
    font-size: 3vw;
    text-align: center;
    max-width: 100%;
  }

  .cardTitle {
    margin-top: 20%;
    color: #0e181e;
    font-family: "DM Sans";
    font-size: 3.5vw;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    text-align: center;
  }

  .cardDescription {
    color: #0e181e;
    font-family: Inter;
    font-size: 3.5vw;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-top: 3%;
    display: none; /* Hidden on mobile */
  }

  .verticalImage {
    width: 100%;
    height: auto;
    border-radius: 12px;
    display: none;
  }
}

@media (max-width: 480px) {
  .description {
    margin-top: 3%;
  }
}
