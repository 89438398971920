.footer {
  display: flex;
  flex-direction: column;
  padding: 5% 5%; /* Adjust padding to be responsive */
  background: #060a28;
  border-top: 1px solid #ddd;
  color: #fff;
}

.row {
  display: flex;
  flex-direction: row;
}

.footerContent {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2%; /* Adjust margin to be responsive */
}

.leftColumn {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 30%; /* Use percentage for min-width */
}

.logo {
  font-size: 3vw; /* Responsive font size */
  font-weight: bold;
  margin-bottom: 2%; /* Adjust margin to be responsive */
}

.contact {
  display: flex;
  align-items: flex-start;
  margin-bottom: 2%; /* Adjust margin to be responsive */
  gap: 2%; /* Adjust gap to be responsive */
  color: #fff;
  font-family: "DM Sans";
  font-size: 1.2vw; /* Responsive font size */
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.contact i {
  margin-right: 1%; /* Adjust margin to be responsive */
}

.rightColumn {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4%; /* Adjust gap to be responsive */
  flex: 1;
  min-width: 50%; /* Use percentage for min-width */
  align-items: end;
}

.column {
  flex: 1;
  min-width: 20%; /* Use percentage for min-width */
}

.column h4 {
  font-size: 1.8vw;
  margin-bottom: 10%;
  /* color: rgba(255, 255, 255, 0.8); */
  font-family: "DM Sans";
  /* font-size: 12px; */
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.column ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.column li {
  margin-bottom: 5%; /* Adjust margin to be responsive */
  text-wrap: nowrap;
}

.column a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.8);
  font-family: "DM Sans";
  font-size: 1.3vw; /* Responsive font size */
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.hr {
  border: 0;
  border-top: 1px solid #ddd;
  margin: 2% 0; /* Adjust margin to be responsive */
}

.bottomRow {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.leftBottom {
  font-size: 1.2vw; /* Responsive font size */
  font-weight: 200;
}
.rightBottom {
  display: flex;
  width: 20%;
  justify-content: space-between;
}
.rightBottom a {
  margin-left: 2%; /* Adjust margin to be responsive */
  text-decoration: none;
  color: rgba(255, 255, 255, 0.8);
  font-family: "DM Sans";
  font-size: 1.2vw; /* Responsive font size */
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.rightBottom a:hover {
  text-decoration: underline;
}
.copyrightText {
  color: rgba(255, 255, 255, 0.8);
  font-family: "DM Sans";
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.staticButton {
  margin-top: 2%;
}

.btn {
  margin-top: 10%;
  display: flex;
  width: fit-content;
  align-items: center;
  padding: 0.5vw;
  border: none;
  font-family: "DM Sans";
  font-size: 1.2vw;
  cursor: pointer;
  gap: 7px;
  border-radius: 8.27px;
  border-radius: 4.064px;
  background: rgba(255, 255, 255, 0.8);
}
.btnText {
  color: #0f153d;
  font-family: Helvetica;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}
.btnIcon {
  align-items: center;
  width: 2.5vw;
}

/* Responsive Design */
@media (max-width: 768px) {
  .btn {
    width: 30vw;
    border-radius: 1vw;
    padding: 1.5vw;
    margin: 4% 0;
    gap: 1.6vw;
  }
  .btnIcon {
    align-items: center;
    width: 3.5vw;
  }
  .btnText {
    font-size: 2vw;
  }
  .logo {
    margin-bottom: 4%;
    /* text-align: right; */
  }
  .footer {
    flex-direction: column;
  }
  .contact {
    color: #fff;
    font-family: "DM Sans";
    font-size: 3vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 4%;
  }
  .row {
    display: flex;
    flex-direction: column;
  }
  .footerContent {
    flex-direction: column;
    align-items: center;
  }
  /* .leftColumn {
    display: ;
  } */
  .rightColumn {
    flex-direction: row;
    gap: 4%; /* Adjust gap to be responsive */
  }

  .column {
    margin-bottom: 4%; /* Adjust margin to be responsive */
  }

  .bottomRow {
    flex-direction: row;
    align-items: center;
  }

  .rightBottom a {
    color: rgba(255, 255, 255, 0.8);
    font-family: "DM Sans";
    font-size: 3vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: block;
    margin: 2% 0; /* Adjust margin to be responsive */
  }
  .column h4 {
    font-size: 3vw;
  }

  .column a {
    font-size: 3vw; /* Responsive font size */
    text-align: left;
  }
  .column ul {
    text-align: left;
  }
  .copyrightText {
    color: rgba(255, 255, 255, 0.8);
    font-family: "DM Sans";
    font-size: 3vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .leftBottom {
    /* font-size: 3vw; */

    color: rgba(255, 255, 255, 0.8);
    font-family: "DM Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .rightBottom {
    display: flex;
    width: 44%;
    justify-content: space-between;
  }
}
