.aboutUsContainer {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 4vw 3vw 5.5vw 7.5vw;
  background: #0f153d;
  position: relative;
  z-index: 0; /* Set a baseline z-index for the container */
}

.imgEclipse {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  z-index: 0; /* Set lower z-index for images */
}

.imgEclipse img {
  height: 100%;
}

.imgEclipseTwo {
  position: absolute;
  right: 0;
  bottom: -4px;
  z-index: 0; /* Set lower z-index for images */
}

.imgEclipseTwo img {
  height: 50%;
}

.imgEclipseThree {
  position: absolute;
  top: 3%;
  right: 28%;
  z-index: 0; /* Set lower z-index for images */
}

.imgEclipseThree img {
  z-index: -1; /* Ensure this is lower than other elements */
}

.leftContent {
  width: 60%;
  flex: 1;
  padding: 4px;
  z-index: 1; /* Higher z-index to ensure content is above images */
}

.heading {
  color: #fff;
  font-family: Roboto;
  font-size: calc(3.2vw + 5px);
  font-style: normal;
  font-weight: 700;
  line-height: 4vw;
  margin: 0;
}

.description {
  color: #fff;
  font-family: Roboto;
  font-size: calc(1vw + 7px);
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin: 1.5rem 0;
}

.buttons {
  display: flex;
  gap: 1rem;
  z-index: 2; /* Ensure buttons are above everything else */
}

.btnPrimary,
.btnSecondary {
  padding: 1vw 1.5vw;
  font-size: 1vw;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  z-index: 2; /* Ensure buttons are above everything else */
}

.btnPrimary {
  display: flex;
  padding: 1vw 2vw;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 76px;
  background: #333f92;
  color: #fff;
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "DM Sans";
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25vw;
}

.btnSecondary {
  display: flex;
  padding: 1.5vw 2.2vw;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 76px;
  border: 1px solid #fff;
  color: #fff;
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "DM Sans";
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25vw;
  background: transparent;
}

.rightContent {
  flex: 1;
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1; /* Ensure image container is above background images */
}

.image {
  min-width: 60%;
  height: auto;
  border-radius: 8px;
  width: 22vw;
  height: 22vw;
  z-index: 1; /* Ensure image is below buttons */
}
@media (max-width: 1200px) {
  .aboutUsContainer {
    padding: 4vw 5vw;
    margin-top: 6%;
  }

  .heading {
    font-size: calc(3vw + 5px);
    line-height: 3.5vw;
  }

  .description {
    font-size: calc(1.2vw + 7px);
    line-height: 28px;
  }

  .image {
    width: 29vw;
    height: 25vw;
  }

  .btnPrimary,
  .btnSecondary {
    font-size: 1vw;
    padding: 1.5vw 2vw;
  }
}
@media (max-width: 992px) {
  .aboutUsContainer {
    flex-direction: column;
    text-align: center;
    padding: 4vw 5vw;
  }

  .leftContent,
  .rightContent {
    width: 100%;
    margin-bottom: 2vw;
  }

  .heading {
    font-size: 7vw;
    line-height: 8.5vw;
  }

  .description {
    font-size: 3vw;
    line-height: 4vw;
  }

  .buttons {
    justify-content: center;
    margin-top: 2vw;
  }

  .image {
    width: 50vw;
    height: auto;
  }

  .btnPrimary,
  .btnSecondary {
    font-size: 1rem;
    padding: 2vw 2.5vw;
  }

  .imgEclipse,
  .imgEclipseTwo,
  .imgEclipseThree {
    display: none;
  }
}
@media (max-width: 768px) {
  .aboutUsContainer {
    flex-direction: column;
    text-align: center;
    padding: 5vw 5vw 5.5vw 5vw;
  }

  .leftContent,
  .rightContent {
    margin-right: 0;
    margin-bottom: 2vw;
    width: 100%;
    margin-top: 7%;
  }

  .heading {
    color: #fff;
    text-align: center;
    font-family: Roboto;
    font-size: 7vw;
    font-style: normal;
    font-weight: 700;
    line-height: 8vw;
  }
  .description {
    font-size: 3.5vw;
    color: #fff;
    text-align: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    line-height: 5vw;
    margin: 5% 0;
  }
  .buttons {
    justify-content: center;
  }

  .imgEclipse,
  .imgEclipseThree,
  .imgEclipseTwo {
    display: none;
  }

  .image {
    min-width: 90%;
    /* height: 50%; */
    border-radius: 8px;
    /* width: 30vw; */
    z-index: 1;
  }
  .btnPrimary,
  .btnSecondary {
    font-size: 0.875rem;
    padding: 2.5vw 3vw;
  }
}
@media (max-width: 576px) {
  .aboutUsContainer {
    padding: 6vw 3vw;
  }

  .heading {
    font-size: 8vw;
    line-height: 9vw;
  }

  .description {
    font-size: 3vw;
    line-height: 5vw;
  }

  .buttons {
    justify-content: center;
  }

  .image {
    width: 70vw;
    height: 70vw;
  }

  .btnPrimary,
  .btnSecondary {
    font-size: 3vw;
    padding: 2.5vw 3vw;
  }
}
@media (max-width: 480px) {
  .aboutUsContainer {
    /* padding: 8vw 3vw 5.5vw 7.5vw; */
  }
  .heading {
    color: #fff;
    text-align: center;
    font-family: Roboto;
    font-size: 8vw;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
  }

  .description {
    font-size: 3.5vw;
    color: #fff;
    text-align: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    line-height: 6vw;
  }

  .btnPrimary,
  .btnSecondary {
    font-size: 3vw;
    padding: 4vw;
  }
}
