.sliderContainer {
  position: relative;
  width: 100%;
  overflow: hidden; /* Hide overflow */
  background: #0f153d;
  padding-bottom: 3%;
}

.slider {
  display: flex;
  position: relative;
  width: 100%;
}

.sliderWrapper {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 300%; /* 100% per slide, so 300% for 3 slides */
}

.slide {
  flex: 1 0 100%; /* Each slide takes up 100% of the slider width */
  box-sizing: border-box; /* Include padding in width calculation */
  display: flex;
  align-items: center;
  padding: 0% 3%;
  gap: 4%;
  flex-direction: row;
}

.textContent {
  width: 60%;
  padding: 2vw;
  box-sizing: border-box; /* Include padding in width calculation */
}

.title {
  font-size: 3vw;
  margin-bottom: 1vw;
  color: #fff;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: 72px; /* 128.571% */
}

.description {
  font-size: 1.5vw;
  margin-bottom: 2vw;
  color: #fff;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: justify;
}

.button {
  padding: 18px 24px;
  font-size: 1vw;
  cursor: pointer;
  border-radius: 75.999px;
  background: #f2f2f2;
  color: #333f92;
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 112.5% */
  border: none;
}

.imageContent {
  width: 40%;
  box-sizing: border-box; /* Include padding in width calculation */
  display: flex;
  justify-content: center;
}

.image {
  /* width: 30vw;  */
  max-width: 24vw;
  height: auto;
  flex-shrink: 0; /* Prevent shrinking */
  border-radius: 12px;
  border: 0.4px solid #fff;
}

.indicators {
  position: absolute;
  bottom: 1vw;
  width: 100%;
  text-align: center;
}

.indicator {
  display: inline-block;
  width: 25px;
  height: 10px;
  border-radius: 24px;
  background: #d1d7ff;
  background-color: #ccc;
  margin: 0 0.5vw;
  cursor: pointer;
}

.indicator.active {
  width: 41px;
  height: 10px;
  border-radius: 24px;
  background: #6b7df1;
}

/* Optional: Arrow styling */
.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  font-size: 2vw;
  cursor: pointer;
  padding: 1vw;
  z-index: 10;
}

.arrow:first-of-type {
  left: 1vw;
}

.arrow:last-of-type {
  right: 1vw;
}

/* Media Queries for Small Devices */
@media (max-width: 768px) {
  .sliderContainer {
    padding-bottom: 8%;
  }
  .sliderWrapper {
    width: 300%; /* No change */
  }

  .slide {
    flex-direction: column;
    gap: 0%;
    /* padding: 3% 3%; */
  }

  .textContent {
    width: 100%;
    padding: 7vw 0px 0 0;
    margin-top: 5vw;
  }

  .title {
    font-size: 5vw;
    line-height: 5vw;
    margin-bottom: 2vw;
    text-align: center;
  }

  .description {
    font-size: 3vw;
    margin-bottom: 3vw;
    text-align: center;
  }

  .button {
    padding: 12px 18px;
    font-size: 3vw;
  }

  .imageContent {
    width: 100%;
    justify-content: center;
    /* padding: 4vw 0; */
  }

  .image {
    width: 80%;
    max-width: 300px;
  }

  .indicators {
    bottom: 2vw;
  }

  .indicator {
    width: 20px;
    height: 8px;
  }

  .indicator.active {
    width: 28px;
    height: 4px;
    border-radius: 24px;
    background: #6b7df1;
  }

  .arrow {
    font-size: 4vw;
    padding: 2vw;
  }

  .arrow:first-of-type {
    left: 2vw;
  }

  .arrow:last-of-type {
    right: 2vw;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 6.5vw;
    line-height: 6vw;
    text-align: center;
    margin-bottom: 7%;
  }

  .description {
    font-size: 3vw;
    margin-bottom: 4vw;
    text-align: justify;
  }

  .button {
    padding: 10px 16px;
    font-size: 4vw;
  }

  .image {
    width: 100%;
    max-width: 100%;
  }

  .indicator {
    gap: 2%;
    width: 5vw;
    height: 6px;
    margin: 0 0.7vw;
  }

  .indicator.active {
    width: 9vw;
    height: 6px;
  }

  .arrow {
    font-size: 5vw;
    padding: 3vw;
  }
  .slide {
    padding: 0 5%;
  }
  .btnDiv {
    display: flex;
    justify-content: center;
    margin: 8% 0;
  }
}
