.container {
  padding: 5vw 0;
  text-align: center;
  background: #f5f8ff;
}

.heading {
  margin-bottom: 3vw;
  color: #0e181e;
  text-align: center;
  font-family: "DM Sans";
  font-size: 4vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.cardsContainer {
  display: flex;
  gap: 2vw;
  margin: 4vw 8vw 0 8vw;
}

.card {
  position: relative; /* Ensure position relative for the ::before pseudo-element */
  border: 0.1vw solid #ddd;
  padding: 2vw;
  max-width: 20.83vw; /* Adjust this value based on your viewport width */
  text-align: center;
  border-radius: var(--Num-radius-lg, 1vw);
  background: var(--Bg-white, #fff);
  overflow: hidden; /* Ensure shine effect stays within card */
}

.icon {
  font-size: 3vw;
  margin-bottom: 1.5vw;
}

.cardTitle {
  color: var(--Text-color-base, #14181f);
  font-family: Roboto;
  font-size: 1.4vw;
  font-style: normal;
  font-weight: 600;
  line-height: 1.4;
  margin: 0;
}

.cardDescription {
  color: #5d6266;
  text-align: center;
  font-family: "DM Sans";
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  margin-top: 1vw;
}

/* Responsive Design */
@media (max-width: 768px) {
  .cardsContainer {
    flex-direction: column;
    align-items: center;
    gap: 5vw;
  }

  .card {
    max-width: 100%;
    border-radius: var(--Num-radius-lg, 12px);
    background: var(--Bg-white, #fff);
    padding: 5vw;
  }
  .container {
    padding: 10vw 0vw;
  }
}

@media (max-width: 480px) {
  .heading {
    font-size: 5vw;
  }

  .icon {
    font-size: 4vw;
  }

  .cardTitle {
    font-size: 4vw;
  }

  .cardDescription {
    font-size: 3.2vw;
  }
  .container {
    padding: 10vw 0vw;
  }
}
