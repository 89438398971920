.wrapper {
  background: #f5f8ff;
  padding: 5vh 5vw;
}

.container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto;
  /* padding: 5vh 5vw; */
  max-width: 100%;
}

.card {
  position: relative; /* Required for the shine effect */
  flex: 1 1 30%;
  padding: 2vw 2vw;
  margin: 1vw 1vw;
  border-radius: 2vw;
  border: 0.1vw solid #cfcfcf;
  background: #fff;
  transition: border 0.3s ease-in-out;
  z-index: 0; /* Ensure each card's before element is on the correct layer */
  overflow: hidden; /* Prevents the shine effect from spilling out of the card */
}

.number {
  text-align: left;
  color: #000;
  font-family: "DM Sans";
  font-size: 2vw;
  font-style: normal;
  font-weight: 700;
  line-height: 2.8vw;
}

.title {
  text-align: left;
  margin: 0;
  color: #333f92;
  font-family: Roboto;
  font-size: 1.8vw;
  font-style: normal;
  font-weight: 500;
  line-height: 5vw;
}

.description {
  text-align: left;
  color: #5d6266;
  font-family: "DM Sans";
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: 2vw;
}

@media (max-width: 768px) {
  .card {
    flex: 1 1 100%;
    margin-bottom: 2vh;
    padding: 6vw;
  }

  .number {
    font-size: 7vw;

    color: #000;
    font-family: "DM Sans";
    /* font-size: 28px; */
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 71.429% */
  }

  .title {
    font-size: 5vw;
    color: #333f92;
    font-family: Roboto;
    /* font-size: 24px; */
    font-style: normal;
    font-weight: 500;
    line-height: 12vw; /* 300% */
    margin: 0;
  }

  .description {
    font-size: 3.5vw;
    color: #5d6266;
    font-family: "DM Sans";
    /* font-size: 16px; */
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .container {
    padding: 0vh 0vw;
  }
}
