.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1vw 5%; /* Adjusted padding to % */
  background: #fff;
  color: #333f92;
  position: fixed; /* Fixed positioning */
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Ensure it's on top of other elements */
}
a {
  text-decoration: none;
  color: #2d2d2d;
  font-family: "DM Sans", sans-serif;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.43;
}
.navItems li {
  text-decoration: none;
  color: #2d2d2d;
  /* text-align: center; */
  font-family: "DM Sans", sans-serif;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.43;
}

.logo {
  font-size: 2vw; /* Adjusted to vw */
  font-weight: bold;
  margin-right: auto; /* Pushes the hamburger icon to the right */
}

.logo img {
  width: 4vw; /* Converted width to vw */
  height: auto; /* Maintain aspect ratio */
}

.hamburger {
  display: none; /* Hidden by default */
}

.hamburger img {
  width: 8vw;
}

.navItems {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.navItem,
.dropItem {
  position: relative;
  padding: 0 1vw;
  cursor: pointer;
  color: #2d2d2d;
  text-align: center;
  font-family: "DM Sans", sans-serif;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.43; /* 20px / 14px */
}
.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background: #fff;
  color: #333f92;
  list-style: none;
  padding: 0;
  margin: 0;
  border-radius: 0.25vw;
  box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 20vw;
  border-radius: 5%;
}

.dropdownItem {
  padding: 1vw 2vw; /* Adjusted padding to vw */
  cursor: pointer;
  text-align: left;

  color: #0e181e;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
.dropdownItem a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #0e181e;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  position: relative;
}

.tickIcon {
  width: 1.5vw; /* Adjust the size as needed */
  height: auto; /* Maintain aspect ratio */
  opacity: 0; /* Hidden by default */
  transition: opacity 0.3s ease-in-out; /* Smooth transition */
}

.dropdownItem a:hover .tickIcon {
  opacity: 1; /* Show the tick icon on hover */
}

.contactButton {
  padding: 1.2vw; /* Adjusted padding to vw */
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1.2vw; /* Adjusted to vw */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5vw; /* Adjusted gap to vw */
  background: #333f92;
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  line-height: 1.125; /* 18px / 16px */
}
@media (max-width: 768px) {
  .navItems {
    display: none; /* Hide on mobile */
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 57px; /* Adjust based on navbar height */
    left: 0;
    background: #fff;
    transition: max-height 0.3s ease-in-out;
    max-height: 0; /* Start with menu hidden */
    overflow: hidden;
    z-index: 999;
  }
  a,
  .dropItem {
    color: #333f92;
    font-family: Poppins;
    font-size: 4vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    padding: 0;
    margin: 0;
  }
  .navItems.show {
    display: flex;
    max-height: fit-content;
    padding: 0 5% 5% 5%;
    /* top: 41px; */
    margin: 4% 0;
  }

  .navbar {
    flex-direction: row; /* Keep logo and hamburger in a row */
    align-items: center;
    padding: 3% 3%;
  }
  .navItems li {
    text-decoration: none;
    color: #2d2d2d;
    text-align: left;
    font-family: "DM Sans", sans-serif;
    font-size: 1.3vw;
    font-style: normal;
    font-weight: 400;
    line-height: 1.43;

    color: #333f92;
  }

  .dropdownItem a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #333f92;
    font-family: "DM Sans";
    font-size: 3.5vw;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    position: relative;
  }
  .navItem {
    padding: 2vw 0; /* Adjusted padding to vw */
    width: 100%;
    text-align: left;
  }

  .dropdown {
    position: static;
    box-shadow: none;
    width: 100%;
    display: block; /* Ensure it displays correctly in column view */
    text-align: left;
    margin: 2% 2% 0 2%;
  }

  .dropdownItem {
    padding: 1.5vw 2vw;
  }

  .hamburger {
    display: block; /* Show hamburger icon on mobile */
  }
  .logo img,
  .hamburger img {
    width: 6vw; /* Converted width to vw */
  }
  .contactButton {
    width: 36%;
    padding: 3vw 3vw;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    font-size: 3vw;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5vw;
    background: #333f92;
    text-align: center;
    font-feature-settings: "liga" off, "clig" off;
    font-family: "DM Sans", sans-serif;
    font-weight: 700;
    line-height: 1.125;
  }
}

@media (max-width: 480px) {
  .navItems {
    display: none; /* Hide on mobile */
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 49px;
    left: 0;
    background: #fff;
    transition: max-height 0.3s ease-in-out;
    max-height: 0; /* Start with menu hidden */
    overflow: hidden;
    z-index: 999;
  }
  a,
  .dropItem {
    color: #333f92;
    font-family: Poppins;
    font-size: 4vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    padding: 0;
    margin: 0;
  }
  .navItems.show {
    display: flex;
    max-height: fit-content;
    padding: 0 5% 5% 5%;
    /* top: 41px; */
    margin: -1% 0;
  }

  .navbar {
    flex-direction: row; /* Keep logo and hamburger in a row */
    align-items: center;
    padding: 3% 4%;
  }
  .navItems li {
    text-decoration: none;
    color: #2d2d2d;
    text-align: left;
    font-family: "DM Sans", sans-serif;
    font-size: 1.3vw;
    font-style: normal;
    font-weight: 400;
    line-height: 1.43;

    color: #333f92;
  }

  .dropdownItem a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #333f92;
    font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    position: relative;
  }
  .navItem {
    padding: 2vw 0; /* Adjusted padding to vw */
    width: 100%;
    text-align: left;
  }

  .dropdown {
    position: static;
    box-shadow: none;
    width: 100%;
    display: block; /* Ensure it displays correctly in column view */
    text-align: left;
    margin: 2% 2%;
  }

  .dropdownItem {
    padding: 1.5vw 2vw;
  }

  .hamburger {
    display: block; /* Show hamburger icon on mobile */
  }
  .logo img,
  .hamburger img {
    width: 7vw; /* Converted width to vw */
  }
  .contactButton {
    width: 25vw;
    padding: 3vw 3vw;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    font-size: 3vw;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5vw;
    background: #333f92;
    text-align: center;
    font-feature-settings: "liga" off, "clig" off;
    font-family: "DM Sans", sans-serif;
    font-weight: 700;
    line-height: 1.125;
  }
}
