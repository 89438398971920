.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2vw; /* Use viewport width for gaps */
  padding: 5vw 4vw; /* Use viewport width for margins */
  flex-direction: column;
  background: #f5f8ff;
}
.leftWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.leftCol {
  min-width: 25vw; /* Use viewport width for minimum width */
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
}

.rightCol {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(20%, 1fr)
  ); /* Responsive grid */
  /* grid-template-rows: repeat(4, 1fr); */
  gap: 2vw; /* Use viewport width for gaps */
  min-width: 30vw; /* Use viewport width for minimum width */
  border-radius: 1vw; /* Use viewport width for border-radius */
  padding: 1vw; /* Use viewport width for padding */
  border-radius: 12px;
  background: #f5f8ff;
  margin-top: 3%;
}

.header {
  color: #333f92;
  font-family: Roboto, sans-serif;
  font-size: 3vw; /* Use viewport width for font-size */
  font-weight: 700;
  line-height: 1.2; /* Use relative units for line-height */
  margin: 0;
}

.subheader {
  margin-top: 3%;
  max-width: 50vw;
  color: #5d6266;
  font-family: "DM Sans", sans-serif;
  font-size: 1.5vw; /* Use viewport width for font-size */
  font-weight: 400;
  line-height: 1.5; /* Use relative units for line-height */
  margin: 0;
  text-align: justify;
}

.button {
  cursor: pointer;
  width: 11vw;
  display: inline-flex;
  padding: 1vw 1vw;
  justify-content: center;
  align-items: center;
  border-radius: 2vw;
  border: 1px solid #333f92;
  color: #333f92;
  text-align: center;
  font-family: "DM Sans", sans-serif;
  font-size: 1.2vw;
  font-weight: 500;
  background: transparent;
  margin-top: 2vw;
}

.card {
  background-color: #f8f9fa;
  padding: 1.5vw; /* Use viewport width for padding */
  border-radius: 1vw; /* Use viewport width for border-radius */
  /* box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.1);  */
  display: flex;
  flex-direction: column;
  width: 100%; /* Full width */
  /* max-width: 45%;  */
  background: #fff;
  border-radius: 12px;
  background: #fff;
}

.title {
  color: #0e181e;
  font-family: Inter, sans-serif;
  font-size: 1.5vw; /* Use viewport width for font-size */
  font-weight: 600;
  line-height: 1.4; /* Use relative units for line-height */
  margin: 5% 0;
}

.description {
  color: #6f7c8e;
  font-family: Inter, sans-serif;
  font-size: 1vw; /* Use viewport width for font-size */
  font-weight: 400;
  line-height: 1.5; /* Use relative units for line-height */
  margin: 0;
}

.icon {
  /* background-color: #007bff; */
  width: 3vw; /* Use viewport width for width */
  height: 3vw; /* Use viewport width for height */
  border-radius: 50%;
  margin-bottom: 1vw;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .rightCol {
    margin-top: 5%;
    grid-template-columns: 1fr;
  }
  .header {
    font-size: 6vw;
    text-align: center;
    line-height: 20vw;
  }
  .subheader {
    font-size: 3vw;
    text-align: center;
    max-width: 100vw;
    width: 100%;
  }
  .button {
    display: none;
  }
  .icon {
    width: 6vw;
    height: 6vw;
  }
  .title {
    color: #0e181e;

    font-family: Inter;
    font-size: 5vw;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    margin: 0;
  }
  .description {
    font-size: 4vw;
    line-height: 5vw;
    text-align: left;
    margin: 2% 0;
  }
  .rightCol {
    padding: 5vw;
    gap: 4vw;
  }
  .card {
    padding: 4vw;
    border-radius: 12px;
    background: #fff;
  }
}
@media (max-width: 480px) {
  .title {
    margin: 2% 0;
  }
  .icon {
    margin-bottom: 5vw;
  }
  .description {
    line-height: 21px;
  }
  .card {
    padding: 6vw;
  }
}
