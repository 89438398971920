.container {
  padding: 2% 4% 4% 4%;
  max-width: 100%;
  margin: 0 auto;
}

.secondDiv {
  width: 83%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header {
  margin-top: 5vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 3vw;
}

.title {
  color: #333f92;
  text-align: center;
  font-family: "DM Sans";
  font-size: 3vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 1vw;
  margin-top: 0px;
}

.description {
  color: #5d6266;
  font-family: "DM Sans";
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 2vw;
  max-width: 74%;
}

.content {
  display: flex;
  justify-content: space-between;
  gap: 2vw;
  flex-wrap: wrap;
}

.leftColumn {
  flex: 1;
  display: grid;
  gap: 2vw; /* Space between cards */
  grid-template-columns: repeat(2, 1fr); /* 2 columns */
  grid-template-rows: auto; /* Auto height for rows */
  align-content: start; /* Prevent stretching */
}

.card {
  padding: 2vw;
  text-align: left;
  border-radius: 8px;
  border: 1px solid #ccc;
  background: #fff;
  height: auto; /* Keep height adaptive */
  box-sizing: border-box; /* Include padding in height calculations */
}

.cardTitle {
  color: #0e181e;
  font-family: "DM Sans";
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 500;
  line-height: 2.5vw;
  margin: 0.5vw 0;
}

.img2 {
  width: 2.5vw;
  height: 2.5vw;
  flex-shrink: 0;
}

.cardDescription {
  color: #0e181e;
  font-family: Inter;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 400;
  margin: 0;
}

.rightColumn {
  flex: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 100%;
}

.rightColumn p {
  color: #5d6266;
  font-family: "DM Sans";
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 1vw;
  text-align: justify;
}

.verticalImage {
  width: 100%;
  height: auto;
  border-radius: 5px;
  flex-shrink: 0;
}

/* Tablet (max-width: 1024px) */
/* @media (max-width: 1024px) {
  .content {
    gap: 1.5vw; 
  }

  .leftColumn {
    grid-template-columns: 1fr 1fr; 
  }

  .rightColumn {
    margin-top: 2vw;
  }
} */

/* Mobile (max-width: 768px) */
/* Mobile (max-width: 768px) */
@media (max-width: 768px) {
  .secondDiv {
    width: 100%;
    flex-direction: column;
  }

  .header {
    text-align: center;
    margin-bottom: 5vw;
    margin-top: 2vw;
  }

  .title {
    font-size: 5vw;
    margin-bottom: 2vw;
  }

  .description {
    font-size: 2.5vw;
    max-width: 100%;
  }

  .content {
    flex-direction: column;
  }

  .rightColumn {
    margin-top: 4vw;
  }

  .card {
    padding: 4vw;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center align content */
    text-align: center; /* Center align text */
  }

  .cardTitle {
    font-size: 3vw;
    line-height: 4vw;
  }

  .cardDescription {
    font-size: 2vw;
  }

  .img2 {
    width: 4vw;
    height: 4vw;
    margin: 0 auto; /* Center align icon */
  }

  .header {
    align-items: center;
  }

  .card {
    border-radius: 8.707px;
    border: none;
    background: #fff;
    border: 1px solid #ccc;
  }

  .rightColumn p {
    margin-top: 2%;
    color: #5d6266;
    text-align: center;
    font-family: "DM Sans";
    font-size: 2.5vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .description {
    font-size: 3.2vw;
  }
  .leftColumn {
    grid-template-columns: repeat(2fr);
  }
}

/* Small Mobile (max-width: 480px) */
@media (max-width: 480px) {
  .container {
    padding: 6% 5%;
  }

  .title {
    font-size: 6vw;
    text-align: center;
    margin-bottom: 4vw;
  }

  .description {
    font-size: 3vw;
  }

  .cardTitle {
    font-size: 5vw;
    line-height: 5vw;
  }

  .cardDescription {
    font-size: 3.5vw;
  }

  .img2 {
    width: 9vw;
    height: 8vw;
    margin: 0 auto; /* Center align icon */
  }

  .header {
    align-items: center;
  }

  .card {
    border-radius: 8.707px;
    border: 1px solid #ccc;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center align content */
    text-align: center; /* Center align text */
  }

  .rightColumn p {
    color: #5d6266;
    text-align: center;
    font-family: "DM Sans";
    font-size: 12px;
  }
}

/* Small Mobile (max-width: 480px) */
@media (max-width: 480px) {
  .container {
    padding: 6% 5%;
  }

  .title {
    font-size: 6vw;
    text-align: center;
  }

  .description {
    font-size: 3.2vw;
    line-height: 4vw;
  }

  .cardTitle {
    font-size: 5vw;
    line-height: 5vw;
    margin: 5% 0;
    color: #0e181e;
    font-family: "DM Sans";
    /* font-size: 20px; */
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 24px */
  }

  .cardDescription {
    font-size: 3.5vw;

    color: #0e181e;
    text-align: center;
    font-family: Inter;
    /* font-size: 16px; */
    font-style: normal;
    font-weight: 400;
    line-height: 22.12px;
  }

  .img2 {
    width: 9vw;
    height: 9vw;
  }
  .header {
    align-items: center;
  }
  .card {
    padding: 8%;
    border-radius: 8.707px;
    border: 1px solid #ccc;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center align content */
    text-align: center; /* Center align text */
  }
  .rightColumn p {
    color: #5d6266;
    text-align: center;
    font-family: "DM Sans";
    font-size: 3vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 6% 0;
  }
  .leftColumn {
    grid-template-columns: 1fr;
    gap: 5vw;
  }
}
