.card {
  flex-basis: 30%;
  margin-bottom: 5%;
  padding: 2%;
  border-radius: var(--Num-radius-lg, 12px);
  background: var(--Bg-white, #fff);
  text-align: center;
  border: 1px solid #eaeaee;
}

.performanceContainer {
  text-align: center;
  padding: 5% 10%;
  background: #f5f8ff;
}

.heading {
  font-size: 2.5vw;
  margin-bottom: 5%;
  color: #0e181e;
  text-align: center;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.cardGrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 5%;
}

.icon {
  font-size: 2vw;
}

.cardTitle {
  font-size: 1.5vw;
  margin-bottom: 2vw;
  color: var(--Text-color-base, #14181f);
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

.cardDescription {
  font-size: 1vw;
  color: #5d6266;
  text-align: center;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.downloadButton {
  padding: 1.4vw 2vw;
  font-size: 1rem;
  color: white;
  border: none;
  cursor: pointer;

  border-radius: 75.999px;
  background: #333f92;
}
/* Responsive Design */
@media (max-width: 768px) {
  .downloadButton {
    padding: 2.4vw;
    font-size: 3vw;
  }
  .cardGrid {
    flex-direction: column;
    gap: 10%;
  }

  .card {
    flex-basis: 100%;
    margin-bottom: 10%;
    padding: 9%;
  }

  .heading {
    font-size: 5vw;
  }

  .icon {
    font-size: 5vw;
  }

  .cardTitle {
    font-size: 4vw;
    margin-bottom: 5vw;
  }

  .cardDescription {
    font-size: 2.5vw;
  }
  .cardDescription {
    font-size: 3.5vw;
  }
  .card {
    padding: 8%;
  }
}

@media (max-width: 480px) {
  .cardDescription {
    font-size: 3.5vw;
  }
  .card {
    padding: 8%;
  }
  .heading {
    font-size: 6vw;
    margin: 6% 0;
  }
  .downloadButton {
    padding: 4% 6%;
    font-size: 3.5vw;
  }
}
