.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3% 5%;
}

.header {
  font-size: 2.5vw;
  color: #333f92;
  text-align: center;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 100px; /* 208.333% */
  margin: 0;
}

.cards {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%; /* 30% width on larger screens */
  padding: 2vh;
  background-color: #fff;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 2vh;
  justify-content: center;
}

.text {
  color: #747474;
  font-family: Roboto, sans-serif;
  font-size: 2vw;
  font-weight: 600;
  line-height: 100px; /* 312.5% */
}

.icon img {
  width: 3.5vw; /* Icon size for larger screens */
  height: auto;
}

@media (max-width: 768px) {
  .cards {
    flex-direction: row; /* Stack the cards vertically */
    justify-content: center; /* Ensure centered alignment */
    align-items: center; /* Center-align the cards */
  }

  .card {
    /* width: 100%;  */
    margin-bottom: 2vh; /* Ensure proper spacing between cards */
    gap: 2vw;
    flex-wrap: nowrap;
    margin-top: 8%;
  }

  .header {
    font-size: 5vw;
    line-height: normal;
    margin-top: 5%;
  }

  .text {
    font-size: 4vw;
    line-height: 1.2;
  }

  .icon img {
    width: 8vw; /* Larger icon size for smaller screens */
    height: auto;
  }
}
@media (max-width: 480px) {
  .header {
    font-size: 6vw;
    line-height: normal;
    margin-top: 5%;
    text-wrap: nowrap;
  }
  .cards {
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: 8%;
  }
}
