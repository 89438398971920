.industryList {
  margin: 0 6%; /* Using percentage for margin */
  display: flex;
  flex-direction: column;
  gap: 4vw; /* Using viewport width for gap */
}

.industry {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4vw; /* Using viewport width for gap */
}

.industryContent {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 2vw; /* Using viewport width for gap */
}

.industryContent.reverse {
  flex-direction: row-reverse; /* Reverse the row direction */
}

.textContent {
  flex: 1;
  text-align: left;
  max-width: 80%; /* Using percentage for max-width */
  box-sizing: border-box; /* Ensures padding doesn't cause overflow */
}

.industryImage {
  flex-shrink: 0;
  display: flex;
  justify-content: flex-end;
}

.industryImage img {
  width: 90%;
  max-width: 40vw; /* Using viewport width for max-width */
  height: 25vw;
  border-radius: 12px;
}

.cardContainer {
  display: flex;
  flex-wrap: wrap; /* Allows wrapping to new lines */
  justify-content: space-between;
  gap: 2vw; /* Using viewport width for gap */
  width: 100%;
  margin-top: 2vw; /* Using viewport width for margin-top */
}

.card {
  position: relative; /* Ensure absolute positioning of pseudo-elements works */
  flex: 1;
  padding: 2vw; /* Using viewport width for padding */
  text-align: center;
  border-radius: 1vw; /* Using viewport width for border-radius */
  background: rgba(241, 243, 255, 0.6);
  box-sizing: border-box; /* Ensures padding and border are included in width */
  transition: 2px 0.4s ease-in-out; /* Smooth transition for border */
  overflow: hidden; /* Ensure content does not overflow */
}

.card h3 {
  margin-bottom: 1vw; /* Using viewport width for margin-bottom */
  color: #2d2d2d;
  font-family: Roboto;
  font-size: 1.5vw; /* Using viewport width for font size */
  font-style: normal;
  font-weight: 600;
  line-height: 1.4; /* Relative line-height */
}

.card p {
  color: #2d2d2d;
  text-align: center;
  font-family: "DM Sans";
  font-size: 1.1vw; /* Using viewport width for font size */
  font-style: normal;
  font-weight: 400;
  line-height: 1.2; /* Relative line-height */
}

.title {
  color: #333f92;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: "DM Sans";
  font-size: 3vw; /* Using viewport width for font size */
  font-style: normal;
  font-weight: 700;
  line-height: 1.6; /* Relative line-height */
  margin-bottom: 0;
}

.hrLine {
  width: 10vw; /* Using viewport width for width */
  height: 0;
  stroke-width: 0.1vw; /* Using viewport width for stroke-width */
  stroke: #333f92;
  color: #333f92;
  float: left;
  margin: 0;
}
.description {
  text-align: justify;
  color: #666;
  font-family: "DM Sans";
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 30px */
}
/* Responsive styles */
@media (min-width: 768px) {
  .industryContent {
    flex-direction: row;
  }

  .industryContent.reverse {
    flex-direction: row-reverse;
    margin-top: 4vw; /* Using viewport width for margin-top */
  }

  .cardContainer {
    flex-direction: row;
  }
  .description {
    font-size: 1.5vw;
  }
  .card p {
    font-size: 1.4vw;
    margin-top: 0 !important;
  }
}

@media (max-width: 767px) {
  .industryContent {
    flex-direction: column;
  }

  .cardContainer {
    flex-direction: column;
    gap: 5vw;
  }

  .industryImage img {
    width: 100%;
    max-width: 100%;
    height: auto;
    margin: 10% 0px;
    border-radius: 12px;
  }

  .title {
    color: #333f92;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: "DM Sans";
    font-size: 5vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
  }

  .hrLine {
    width: 15%;
    margin: 0 auto; /* Centered horizontally */
    float: none; /* Remove float */
    display: block; /* Ensure it's a block element */
  }
  /* .indusDes {
    font-size: 1.4vw;
    margin-bottom: 1vw;
    color: #5d6266;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  } */
  .description {
    text-align: justify;
    color: #666;
    font-family: "DM Sans";
    font-size: 3vw;
    font-style: normal;
    /* font-weight: 400; */
    line-height: 150%; /* 30px */
  }

  .card h3 {
    color: #2d2d2d;
    font-family: Roboto;
    font-size: 4vw;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 18.261px */
  }

  .card p {
    font-size: 3vw;
    line-height: normal;
  }

  .card {
    padding: 7vw 3vw;
  }
  .textContent {
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .description {
    font-size: 3vw;
  }
  .title {
    text-align: center;
  }
  .hrLine {
    margin-top: 2%;
  }
}
